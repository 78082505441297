import { jsonConverter } from "components/helpers/jsonConverter";
import { gql } from "graphql-request";
import { nhost } from "utility";
// const { data: user } = useGetIdentity();

export const GET_ORG = async (id) => {
  let query = `
  query MyQuery {
  ob_orgs(where: {id: {_eq: "${id}"}}) {
    minimum_further
  }
  }
  `;
  const { data } = await nhost.graphql.request(query);
  return data;
};

export const GET_CATEGORIES = async () => {
  let QUERY = `
  query ($id: uuid ) {
    orders(where: {ob_org_id: {_eq: "e92f767c-febd-4fe3-9e0e-59fd0d0291ac"}}){
      id
      name
      items
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const GET_ORDERS = async (uuid) => {
  let QUERY = `
  query MyQuery ($uuid: uuid){
    orders(where: { ob_org_id: { _eq: "${uuid}" } }, order_by: { created_at: desc }) {
      id
      name
      date
      email
      items
      phone_no
      status
      order_Unique_id
      created_at
      address
      sage_order_ref_no
      additional_charges
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ORDERS_LIST = async (uuid, customer_id) => {
  let QUERY = `
  query MyQuery ($uuid: uuid){
    orders(where: { ob_org_id: { _eq: "${uuid}" }, customer_id: { _eq: "${customer_id}"} }, order_by: { created_at: desc }) {
      order_Unique_id
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_INVOICE_LIST = async (uuid, orderIDs) => {
  let QUERY = `
    query GetInvoices($uuid: uuid, $orderIDs: [String!] ) {
      invoices(
        where: {
          ob_org_id: {_eq: $uuid},
          orderids: {_in: $orderIDs}
        },
        order_by: { created_at: desc }
      ) {
        advance_amount
        comments
        created_at
        created_by
        credit_amount
        discounts
        id
        invoice_date
        invoicetype
        is_paid
        is_posted_to_sage_sa
        isactive
        modified_at
        modified_by
        ob_org_id
        orderids
        outstanding
        paid_amount
        payeedetails
        paymentgateway
        paymentmode
        paymentreference
        price
        status
        total
        sage_invoice_no
      }
    }
  `;

  const variables = {
    uuid: uuid,
    orderIDs: orderIDs.map((order) => order.order_Unique_id),
  };

  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};

export const GET_INVOICE = async (uuid, orderID) => {
  let QUERY = `
    query GetInvoices($uuid: uuid, $orderID: String! ) {
      invoices(
        where: {
          ob_org_id: {_eq: $uuid},
          orderids: {_eq: $orderID}
        },
        order_by: { created_at: desc }
      ) {
        advance_amount
        comments
        created_at
        created_by
        credit_amount
        discounts
        id
        invoice_date
        invoicetype
        is_paid
        is_posted_to_sage_sa
        isactive
        modified_at
        modified_by
        ob_org_id
        orderids
        outstanding
        paid_amount
        payeedetails
        paymentgateway
        paymentmode
        paymentreference
        price
        status
        total
        sage_invoice_no
      }
    }
  `;

  const variables = {
    uuid: uuid,
    orderID,
  };

  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};

export const GET_INVOICE_LIST_SAVE = async (uuid, orderIDs) => {
  let QUERY = `
    query GetInvoices($uuid: uuid, $orderIDs: [String!] ) {
      invoices(
        where: {
          ob_org_id: {_eq: $uuid},
          orderids: {_in: $orderIDs}
        },
        order_by: { created_at: desc }
      ) {
        advance_amount
        comments
        created_at
        created_by
        credit_amount
        discounts
        id
        invoice_date
        invoicetype
        is_paid
        is_posted_to_sage_sa
        isactive
        modified_at
        modified_by
        ob_org_id
        orderids
        outstanding
        paid_amount
        payeedetails
        paymentgateway
        paymentmode
        paymentreference
        price
        status
        total
        sage_invoice_no
      }
    }
  `;

  const variables = {
    uuid: uuid,
    orderIDs: orderIDs.map((order) => order),
  };

  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};

export const UPDATE_INVOICE_ID = async (uuid, orderIDs) => {
  let QUERY = `
  mutation MyMutation($uuid: uuid, $orderIDs: [String!]) {
    update_invoices(
      where: {
        ob_org_id: { _eq: $uuid }
        orderids: { _in: $orderIDs }
      }
      _set: { is_paid: true, status: "Payment Success" }
    ) {
      affected_rows
    }
  }  
  `;
  const variables = {
    uuid: uuid,
    orderIDs: orderIDs,
  };

  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};

export const UPDATE_INVOICE_BALANCE = async (uuid, outstanding) => {
  let QUERY = `
  mutation MyMutation($id: uuid, $outstanding: numeric) {
    update_invoices(
      where: { id: { _eq: $id } }
      _set: { outstanding: $outstanding }
    ) {
      affected_rows
    }
  }
  `;
  const variables = {
    id: uuid,
    outstanding: outstanding,
  };

  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};

export const UPDATE_CUSTOMER_BALANCE = async (uuid, balance, payment) => {
  let QUERY = `
  mutation MyMutation($id: uuid, $balance: float8, $payment: float8) {
    update_customers(
      where: {id: {_eq: $id}},
      _set: {
        total_balance_amount: $balance,
        total_payment_amount: $payment
      }
    ) {
      affected_rows
    }
  }
  
  `;
  const variables = {
    id: uuid,
    balance: balance,
    payment: payment,
  };

  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};

export const GET_CURRENCY = async () => {
  let QUERY = `
  query MyQuery {
    currency{
        id
        name
      }
    }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const GET_FAVLIST = async (uuid) => {
  let QUERY = `
  query MyQuery($id: uuid) {
    favorite_lists(where: {id: {_eq: "${uuid}"}}){
        id
        name
        customer_id
      }
    }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_CUSTOMER_FAVLIST = async (uuid) => {
  let QUERY = `
  query MyQuery($id: uuid) {
    favorite_lists(where: {customer_id: {_eq: "${uuid}"}}){
        id
        name
        customer_id
      }
    }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_TRIPS = async (uuid) => {
  let QUERY = `
  query MyQuery($id: uuid) {
    trip(where: {ob_org_id: {_eq: "${uuid}"}}){
        id
        route_id
        ob_org_id
        date
        driver_name
        vehicle_number
        notes
        start_time
        end_time
        name
        due_time
        seq_number
      }
    }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const GET_FAVCUSTOMER = async (uuid) => {
  let QUERY = `
  query MyQuery($id: uuid) {
    customers(where: {id: {_eq: "${uuid}"}}){
        id
        ob_org_id
        name
        address
        email
      }
    }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_CATEGORIE = async (uuid) => {
  let QUERY = `query MyQuery($id: uuid) {
    product_option(where: {id: {_eq: "${uuid}"}}) {
      name
      list_value
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Banner = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
    update_banners(where: { id: { _eq: "${id}" } }, _set: { active: "false" }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Banners = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_banners(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_News = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_news(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Taxes = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_taxes(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_FAQS = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_faqs(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_PAYMENT_OPTIONS = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_payment_options(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_ROUTE = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_route(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_TRIP = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_trip(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_ABOUTUS = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_aboutus(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Pages = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_pages(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Category = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_categories(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Payment_options = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_payment_options(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Product_Option = async (id) => {
  let QUERY = `
  mutation MyMutation($id:uuid) {
    delete_product_option(where: {id: {_eq: "${id}"}}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_BRANDS = async (id) => {
  let QUERY = `
  mutation MyMutation($id:uuid) {
    delete_brands(where: {id: {_eq: "${id}"}}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_pricelists = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
    delete_pricelists(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Coupons = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
    delete_coupons(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Customer = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
   delete_customers(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_USER = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
    deleteUser( id: "${id}") {
      id
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Product = async (id) => {
  let QUERY = `
  mutation Mymutation($id: uuid) {
    delete_products(where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_CUSTOMER_GROUP = async (id) => {
  let QUERY = `mutation Mymutation($id: uuid) {
  delete_customer_groups(where: { id: {_eq: "${id}" } }) {
    affected_rows
  }
 }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Delete_Order = async (id) => {
  let QUERY = `
  mutation MyMutation($id: uuid) {
    update_orders(where: {id: { _eq: "${id}" }}, _set: {status: "Cancelled"}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const DELETE_ORDER_STATUS = async (id) => {
  let QUERY = `
  mutation MyMutation($id: uuid) {
    delete_order_statuses(where: {id: { _eq: "${id}" }}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_MEASURINGUNIT = async (uuid) => {
  let QUERY = `query MyQuery($id: uuid) {
    measurement_unit(where: {ob_org_id: {_eq: "${uuid}"}}) {
      name
      id
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Update_Order = async (id, value) => {
  let QUERY = `
  mutation MyMutation($id: uuid) {
    update_orders(where: {id: { _eq: "${id}" }}, _set: {status: "${value}"}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const Update_Status = async (id, value) => {
  let QUERY = `
  mutation MyMutation($id: uuid) {
    update_orders(where: {id: { _eq: "${id}" }}, _set: {status: "${value}"}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const Update_Trip = async (id, value) => {
  let QUERY = `
  mutation MyMutation($id: uuid, $value: uuid) {
    update_orders(where: {id: { _eq: "${id}" }}, _set: {trip_id: "${value}"}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ALL_PRODUCTS = async (uuid) => {
  let QUERY = `query MyQuery($uuid: uuid) {
    products(where: {ob_org_id : {_eq: "${uuid}"}}) {
      id
      product_unique_id
      name
      ob_org_id
      ob_product_id
      category_id
      code
      description
      image
      length
      width
      height
      msrp
      minimum_quantity
      maximum_quantity
      quantity
      allow_backorder
      track_inventory
      box_quantity
      status
      vat_class
      is_active
      upccode
      referencecode
      packagequantity
      metadescription
      searchkeywords
      promote_category
      featured_product
      deals
      status_product
      product_id
      buy_with
      brand_id
      apply_discounts
      price
      customer_id
      categories
      product_status_rules
      customer_price
      related_products
      access_private
      access_customer_id
      access_private_group
      price_details
      upload_image
      price_data
      product_option_id
      product_option
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ALL_PRODUCTS_WITH_ORGID = async (uuid) => {
  let QUERY = `query MyQuery($uuid: uuid) {
    products(where: {is_active: {_eq: true}, ob_org_id: {_eq: "${uuid}"}}) {
      id
      product_unique_id
      name
      ob_org_id
      ob_product_id
      category_id
      code
      description
      image
      length
      width
      height
      msrp
      minimum_quantity
      maximum_quantity
      quantity
      allow_backorder
      track_inventory
      box_quantity
      status
      vat_class
      is_active
      upccode
      referencecode
      packagequantity
      metadescription
      searchkeywords
      promote_category
      featured_product
      deals
      status_product
      product_id
      buy_with
      brand_id
      apply_discounts
      price
      customer_id
      categories
      product_status_rules
      customer_price
      related_products
      access_private
      access_customer_id
      access_private_group
      price_details
      upload_image
      price_data
      product_option_id
      product_option
      tax_id
      qty_allocated
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const GET_ALL_CUSTOMER_GRPS_WITH_ORGID = async (uuid) => {
  let QUERY = `query MyQuery($uuid: uuid) {
    customer_groups(where: {ob_org_id: {_eq: "${uuid}"}}) {
      id,
      group_name,
      ob_org_id,
      pricelist_id,
      customer_ids,
      hide_product_ids,
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ALL_PAYMENT_OPTIONS = async (uuid) => {
  let QUERY = `query MyQuery($uuid: uuid) {
    payment_options(where: {ob_org_id: {_eq: "${uuid}"}}) {
      id,
      name,
      ob_org_id,
      description,
      payment_fee_percentage,
      payment_fee_amount,
      is_active,
      private,
      secrete_keys,
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const UPDATE_CUSTOMER_GROUPS = async (cusGrpId, updatedCustomerIds) => {
  let QUERY = `
  mutation UpdateCustomerGrps($cusGrpId: uuid, $updatedCustomerIds: String) {
    update_customer_groups(
      where: { id: { _eq: "${cusGrpId}" } }
      _set: { customer_ids: "${updatedCustomerIds}" }
    ) {
      affected_rows
    }
  }
`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const UPDATE_CUSTOMERS = async (customerId, Objects) => {
  const CUSTOMERMUTATIONAPI = `
  mutation UpdateCustomer($customerId: uuid, $Objects: customers_set_input!) {
    update_customers(
      where: { id: { _eq: "${customerId}" } },
      _set: (Objects :$Objects)
    ) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`;

  const { data } = await nhost.graphql.request(CUSTOMERMUTATIONAPI, Objects);
  return data;
};

export const GET_ALL_CUSTOMERS = async (uuid) => {
  let QUERY = `query MyQuery($uuid: uuid) {
    customers(where: {ob_org_id: {_eq: "${uuid}"}}) {
        id,
        name,
        address,
        status,
        fullname,
        activity,
        specify_activity,
        email,
        price_list,
        discount_rate,
        created_at,
        min_order_value,
        phone,
        address_line_2,
        city,
        province,
        country,
        postal_code,
        website,
        company_number,
        customer_reference_code,
        privacy_group,
        vat_number,
        admin_description,
        notify_customer_email,
        is_active,
        disable_ordering,
        outgoing_email,
        sales_rep,
        addresses,
        order_as_XLS,
        order_as_PDF,
        receive_email_notify,
        new_order_notification,
        bcc_outging_email,
        customer_homepage_products,
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_TBC_ITEMS = async (payload) => {
  const query = `
  query MyQuery  {
      to_be_charged(where: {customer_id: {_eq: "${payload?.id}"}, org_id: {_eq: "${payload?.ob_org_id}"}}) {
      id
      items
      customer_id
    }
  }
  `;
  const { data } = await nhost.graphql.request(query);
  return data;
};

export const GET_TBC_ITEMS_ID = async (payload) => {
  const query = `
  query MyQuery  {
      to_be_charged(where: {id: {_eq: "${payload?.to_be_charged_id}"}, org_id: {_eq: "${payload?.ob_org_id}"}}) {
      id
      items
      customer_id
    }
  }
  `;
  const { data } = await nhost.graphql.request(query);
  return data;
};

export const GET_ALL_CUSTOMERS_WITH_ORGID = async (uuid) => {
  let QUERY = `query MyQuery($uuid: uuid) {
    customers(where: {ob_org_id: {_eq:"${uuid}"}}) {
        id,
        name,
        address,
        status,
        ob_org_id,
        fullname,
        activity,
        email,
        price_list,
        discount_rate,
        created_at,
        min_order_value,
        phone,
        address_line_2,
        city,
        province,
        country,
        postal_code,
        website,
        company_number,
        customer_reference_code,
        privacy_group,
        vat_number,
        is_active,
        sales_rep,
        customer_org_id,
        to_be_charged_id
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
// export const GET_CUSTOMERS_NOT_IN_CUSTOMERGRPS = async (customerIds, cond) => {
//   let QUERY = `query MyQuery($id:[uuid] = []) {
//     customers(where: { id: {_nin: $id}}) {
//         id,
//         name,
//         address,
//         status,
//         fullname,
//         activity,
//         specify_activity,
//         email,
//         price_list,
//         discount_rate,
//         created_at,
//         min_order_value,
//         phone,
//         address_line_2,
//         city,
//         province,
//         country,
//         postal_code,
//         website,
//         company_number,
//         customer_reference_code,
//         privacy_group,
//         vat_number,
//         admin_description,
//         notify_customer_email,
//         is_active,
//         disable_ordering,
//         outgoing_email,
//         sales_rep,
//         addresses,
//         order_as_XLS,
//         order_as_PDF,
//         receive_email_notify,
//         new_order_notification,
//         bcc_outging_email,
//         customer_homepage_products,
//     }
//   }`;

//   const { data } = await nhost.graphql.request(QUERY, cond);
//   return data;
// };
export const GET_ALL_CATEGORIES = async (uuid) => {
  let QUERY = `query MyQuery {
    categories {
        id,
        name,
        ob_org_id,
        description,
        is_active,
        created_at,
        updated_at
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const GET_CATEGORIES_BY_ORGID = async (userid) => {
  let QUERY = `
  query MyQuery($userid: uuid ) {
    categories(where: {ob_org_id: {_eq: "${userid}"}, is_active: {_eq: "${true}"}}){
      id,
      name,
      ob_org_id,
      description,
      is_active,
      created_at,
      updated_at
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_PRODUCT_REVIEWS = async (userid) => {
  let QUERY = `
  query MyQuery($userid: uuid ) {
    product_reviews(where: {ob_org_id: {_eq: "${userid}"}}){
      id,
      product_id,
      ob_org_id,
      user_id,
      is_active,
      created_at,
      updated_at,
      rating,
      
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const GET_PRICELIST = async (userid) => {
  let QUERY = `
  query MyQuery($userid: uuid ) {
    pricelists(where: {ob_org_id: {_eq:"${userid}"}}){
      id,
      name,
      short_name,
      created_at,
      updated_at
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const ORG_NAME = async (uuid) => {
  let QUERY = `query MyQuery($id: uuid) {
    ob_orgs(where: {id: {_eq: "${uuid}"}}) {
      orgname_prefix
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const USER_DATA = async (uuid) => {
  let QUERY = `query($metadata: uuid) {    
    users(where: { metadata: { _eq: ${jsonConverter(uuid)} } }) {
      email
      displayName
      phoneNumber
      metadata
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GRT_ALL_USER_DATA = async (uuid) => {
  let QUERY = `query {    
    users {
      id
      email
      displayName
      phoneNumber
      metadata
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_USER = async (email) => {
  let QUERY = `query($email: string) {    
    users(where: { email: { _eq: "${email}" } }) {
      email
      displayName
      phoneNumber
      id
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};
export const PRODUCT_UNIQUE_ID = async (uuid) => {
  let QUERY = `query MyQuery($ob_org_id: uuid) {
    products(order_by: {created_at: desc}, where: {ob_org_id: {_eq: "${uuid?.ob_org_id}"}}) {
      product_unique_id
      name
    }
  }`;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ALL_CATEGORIES_BY_FAVORITE_LIST_ID = async (id, cond) => {
  let QUERY = `query get_all_categories_by_favorite_list_id($object:categories_bool_exp, $limit:Int,$offset:Int) {
    categories(where:$object,order_by:[{order: asc_nulls_last}, {name: asc}], limit:$limit, offset:$offset){ 
     id, 
     uid, 
     name, 
     is_active,
     order,
     products(where:{
       favorite_lists_items:{favorite_list_id:{_eq:"${id}"}}}){
       name
       quantity
       favorite_lists_items_aggregate{
         
         aggregate
         {
           count
         }
         
       }
     }
     created_at
      } 
     categories_aggregate(where:$object){
        aggregate{ count } 
       } 
     }`;

  const { data } = await nhost.graphql.request(QUERY, cond);

  return data;
};

export const MUTATE_MULTIPLE_CATEGORIES = async (id, set) => {
  const QUERY = `mutation UpdateMultipeCategories($set: [categories_updates!]!) {
  update_categories_many(updates: $set) {
    affected_rows
    returning {
      id
      name
      uid,
      is_active
      order
      created_at
      products(where:{
        favorite_lists_items:{favorite_list_id:{_eq:"${id}"}}}){
        name
        quantity
        favorite_lists_items_aggregate{
          
          aggregate
          {
            count
          }
          
        }
      }
    }
  }
}
 
  `;
  const { data } = await nhost.graphql.request(QUERY, set);

  return data;
};

export const GET_FAVOURITELIST_ITEMS = async (id, cond) => {
  let QUERY = `
query MyQuery ($id: uuid){
  favorite_lists_items( order_by:[{order: asc_nulls_last}, {product: {name: asc}}],where:{is_active: {_eq: true}, product: {is_active: {_eq: true}}, favorite_list_id: {_eq: "${id}"}}) {
    product {
      name
      ob_org_id
      access_customer_id
      access_private
      access_private_group
      allow_backorder
      apply_discounts
      barcode
      box_quantity
      brand_id
      buy_with
      categories
      category_id
      code
      cost_price
      created_at
      created_by
      customer_id
      customer_price
      deals
      description
      featured_product
      final_price
      height
      id
      image
      is_active
      length
      maximum_quantity
      metadescription
      minimum_quantity
      msrp
      ob_product_id
      packagequantity
      price
      price_data
      price_details
      pricelist
      product_id
      product_option
      product_option_id
      product_status_rules
      promote_category
      quantity
      referencecode
      temp_img
      related_products
      searchkeywords
      status
      status_product
      track_inventory
      unit_weight
      upccode
      upload_image
      updated_by
      vat_class
      qty_allocated
      width
    }
  }
}
`;
  const { data } = await nhost.graphql.request(QUERY, cond);

  return data;
};

export const GET_FAVITEM_CATEGORIES = async (id, cond) => {
  let QUERY = `
query ($id: uuid ) {
  categories(where: {ob_org_id: {_eq: "${id}"}, is_active: {_eq: true}}, order_by: {order: asc}){
    description
    id
    image_url
    is_private
    name
    ob_category_id
    ob_org_id
  }
}
`;
  const { data } = await nhost.graphql.request(QUERY, cond);

  return data;
};

export const GET_ALL_TAXES = async (id) => {
  let QUERY = `query ($id: uuid ) {
  taxes(where: {ob_org_id: {_eq: "${id}"}, is_active: {_eq: true}}){
    id
    vat_class
    vat_customer_group
    name
    ob_org_id
    created_by
    updated_by
    sage_tax_code
    is_active
    vat_rate
  }
}
`;
  const { data } = await nhost.graphql.request(QUERY);

  return data;
};

export const GET_FAVOURITE_LIST_ITEMS_BY_FAVORITE_LIST_ID = async (
  id,
  cond
) => {
  const QUERY = `query get_favorite_list_items_by_favorite_list_id($object:favorite_lists_items_bool_exp, $limit:Int,$offset:Int) {

    favorite_lists_items(where:$object,order_by:[{order: asc_nulls_last}, {product:{name:asc}}],  limit:$limit, offset:$offset){
      id
      uid
      is_active
      order
      product{
        id 
        name
      }
    }

    favorite_lists_items_aggregate(where:$object, limit:$limit, offset:$offset){
      aggregate{
        count
      }
    }

  }`;

  const { data } = await nhost.graphql.request(QUERY, cond);

  return data;
};

export const MUTATE_MULTIPLE_FAVORITE_ITEMS = async (id, set) => {
  const QUERY = `mutation UpdateMultipeItems($set: [favorite_lists_items_updates!]!) {
    update_favorite_lists_items_many(updates: $set) {
      affected_rows
      returning {
        id
        uid
        quantity
        is_active
        order
        created_at
        product {
          id
          name
          image
        }
      }
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY, set);

  return data;
};
export const UPDATE_CUSTOMER_PRODUCTS_PRIVACY = async (id, set) => {
  const QUERY = `mutation UpdateMultipeItems($set: [customer_product_privacy_updates!]!) {
    update_customer_product_privacy_many(updates: $set) {
      affected_rows
      returning {
        id
      }
    }
  }
  `;
  const { data } = await nhost.graphql.request(QUERY, set);
  return data;
};

export const DELETE_FAVORITE_ITEM = async (id) => {
  const QUERY = `mutation deleteItem($id: uuid!){
    delete_favorite_lists_items_by_pk(id:$id){
      id
    }
  }`;

  const result = await nhost.graphql.request(QUERY, { id: id });

  return result;
};

export const DELETE_FAVORITE_LIST = async (id) => {
  const QUERY = `
  mutation Mymutation($id: uuid) {
   delete_favorite_lists       (where: { id: { _eq: "${id}" } }) {
      affected_rows
    }
  }
  `;
  const result = await nhost.graphql.request(QUERY);

  return result;
};

export const QUERY_EXCEL_PRODUCTS = async (cond) => {
  const QUERY = `query excel_products($where:products_bool_exp) {
    products(where:$where){
      id
      name
      code
    }
  }`;

  const result = await nhost.graphql.request(QUERY, cond);

  return result;
};

export const INSERT_FAVORITE_LISTS = async (cond) => {
  const QUERY = `
  mutation MyMutation($objects: [favorite_lists_items_insert_input!]!) {
    s_items(objects: $objects, 
      on_conflict: {
        constraint: favorite_lists_items_favorite_list_id_product_id_key,
        update_columns: []
      }) {
      affected_rows
    }
  } `;
  const result = await nhost.graphql.request(QUERY, cond);

  return result;
};

export const INSERT_FRANCHISE_ITEMS = async (cond) => {
  const QUERY = `
  mutation MyMutation($objects: [franchise_items_insert_input!]!) {
    insert_franchise_customer_customer_group_mappings(objects: $objects, 
      on_conflict: {
        constraint: franchise_items_franchise_id_product_id_key, 
        update_columns: []
      }) {
      affected_rows
    }
  }`;

  const result = await nhost.graphql.request(QUERY, cond);

  return result;
};

export const GET_FRANCHISE = async () => {
  let QUERY = `
   query MyQuery {
    franchise_customer_customer_group_mappings {
      id
      name
    }
  }
  `;
  try {
    const { data, errors } = await nhost.graphql.request(QUERY);
    if (errors) {
      console.error("GraphQL Errors:", errors);
    }
    console.log("GraphQL Data:", data);
    return data;
  } catch (error) {
    console.error("GraphQL Request Failed:", error);
    return null;
  }
};

export const GET_FRANCHISE_BY_CUSTOMER_ID = async (customer_org_id) => {
  let QUERY = `
   query MyQuery {
    franchise_customer_customer_group_mappings(where: {customer_id: {_eq: "${customer_org_id}"}, is_active: {_eq: true}}) {
      id
      name
    }
  }
  `;
  try {
    const { data, errors } = await nhost.graphql.request(QUERY);
    if (errors) {
      console.error("GraphQL Errors:", errors);
    }
    return data;
  } catch (error) {
    console.error("GraphQL Request Failed:", error);
    return null;
  }
};

export const DELETE_FRANCHISE_BY_CUSTOMER_ID = async (id) => {
  const QUERY = `
    mutation DeleteFranchise {
      update_franchise_customer_customer_group_mappings(where: {id: {_eq: "${id}"}}, _set: {is_active: false}) {
        affected_rows
      }
    }
  `;
  try {
    const { data, errors } = await nhost.graphql.request(QUERY);
    if (errors) {
      console.error("GraphQL Errors:", errors);
      return null;
    }
    return data;
  } catch (error) {
    console.error("GraphQL Request Failed:", error);
    return null;
  }
};

export const GET_PRODUCTS_NOT_IN_FAVORITE_LIST = async (orgn_id, cond) => {
  const QUERY = `query GetProductsNotInFavoriteList( $limit: Int!, $offset: Int!, $favoriteListId: uuid!,$where:products_bool_exp!) {
    get_products_not_in_favorite_list(limit: $limit, offset: $offset, where:$where,args: {list_id: $favoriteListId,org_id:"${orgn_id}"}) {
      id
      name
     
    }   
  }`;
  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const INSERT_FAVORITE_LIST_ITEMS = async (cond) => {
  const QUERY = `mutation InsertFavoriteListsItems(
    $Object: [favorite_lists_items_insert_input!]!) {
    insert_favorite_lists_items(objects:$Object) {
      affected_rows
      returning {
        id
   
      }
    }
  }
      `;

  const result = await nhost.graphql.request(QUERY, cond);

  return result;
};
export const INSERT_ORDERS = async (cond) => {
  const QUERY = `mutation InsertOrders(
    $Object: [orders_insert_input!]!) {
      insert_orders(objects:$Object) {
      affected_rows
      returning {
        id
        name
        order_Unique_id
      }
    }
  }
  `;
  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const INSERT_FRANCHISE = async (cond) => {
  const QUERY = `
    mutation InsertFranchise($Object: [franchise_customer_customer_group_mappings_insert_input!]!) {
      insert_franchise_customer_customer_group_mappings(objects:$Object) {
       affected_rows
      returning{
        id
        name
        customer_id
        customer_group_id
        ob_org_id
        }
      }
    }
  `;
  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const GET_FRANCHISE_PRODUCT_LIST = async (franchise_mapping_id) => {
  let QUERY = `
  query MyQuery {
    franchise_product_list(where: {franchise_mapping_id: {_eq: "${franchise_mapping_id}"},is_active: {_eq: true}}) {
      id
      is_active
      franchise_product_list_product {
        id
        name
      }
    }
  }
  `;
  try {
    const { data, errors } = await nhost.graphql.request(QUERY);
    if (errors) {
      console.error("GraphQL Errors:", errors);
    }
    return data;
  } catch (error) {
    console.error("GraphQL Request Failed:", error);
    return null;
  }
};

export const DELETE_FRANCHISE_PRODUCT_LIST = async (id) => {
  const QUERY = `mutation UpdateFranchiseProductList($id: uuid!) {
    update_franchise_product_list(where: {id: {_eq: $id}}, _set: {is_active: false}) {
      affected_rows
    }
  }`;
  const result = await nhost.graphql.request(QUERY, { id });
  return result;
};

export const GET_PRODUCTS_NOT_IN_FRANCHISE_LIST = async (orgn_id, cond) => {
  const QUERY = `query GetProductsNotInFranchiseList($limit: Int!, $offset: Int!, $franchiseListId: uuid!, $where: products_bool_exp!) {
    get_products_not_in_franchise_list(
      limit: $limit,
      offset: $offset,
      where: $where,
      args: { list_id: $franchiseListId, org_id: "${orgn_id}" }
    ) {
      id
      name
    }
  }`;

  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const INSERT_FRANCHISE_LIST_ITEMS = async (cond) => {
  const QUERY = `mutation InsertFranchiseListItems($Object: [franchise_product_list_insert_input!]!) {
    insert_franchise_product_list(objects: $Object) {
      affected_rows
      returning {
        id
      }
    }
  }`;

  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const INSERT_INVOICES = async (cond) => {
  const QUERY = `mutation InsertInvoices($Object: [invoices_insert_input!]!) {
      insert_invoices(objects:$Object) {
      affected_rows
      returning {
        id
        orderids
      }
    }
  }
  `;
  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const INSERT_CUSTOMER_PRODUCT_PRIVACY = async (cond) => {
  const QUERY = `mutation InsertCustomerProductPrivacy($Objects: [customer_product_privacy_insert_input!]!) {
    insert_customer_product_privacy(objects: $Objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const EXCEL_INSERT_FAVORITE_LISTS = async (cond) => {
  const QUERY = `mutation InsertFavoriteLists($Object:[favorite_lists_insert_input!]!) {
    insert_favorite_lists(objects: $Object) {
      affected_rows
   
    }
  }`;

  const result = await nhost.graphql.request(QUERY, cond);

  return result;
};

export const CHECK_FAVORITE_LIST = async (param) => {
  const QUERY = `query MyQuery($id: uuid) {
  favorite_lists(where: {customer_id: {_eq: $id}}) {
    id
    name
    customer_id
    is_active
    }
  }`;

  const result = await nhost.graphql.request(QUERY, param);
  return result;
};

export const GET_CUSTOMERS = async (cond) => {
  const QUERY = `query GetCustomers( $limit: Int!, $offset: Int!, $where:customers_bool_exp!) {
    customers(limit: $limit, offset: $offset, where:$where, ) {
      id
      name
      
    }   
  }`;

  const result = await nhost.graphql.request(QUERY, cond);
  return result;
};

export const GET_CUSTOMER_RECEIPT = async (org_id) => {
  let QUERY = `
  query MyQuery($org_id: uuid) {
    customer_receipt(order_by: {created_at: desc}, where: {ob_org_id: {_eq: "${org_id}"}}) {
      amount_paid
      id
      receipt_no
      balance_amount
      created_at
      created_by
      customer_id
      invoice_reference_no
      is_active
      modified_at
      modified_by
      payment_reference_no
      ob_org_id
      payment_type
      receipt_date
    }
  }
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_CUSTOMER_SEARCH = async (Id, org_id) => {
  let QUERY = `
  query MyQuery ($Id: uuid, $org_id: uuid){
    customers(where:  {ob_org_id: {_eq: "${org_id}"}, id: {_eq: "${Id}"}}) {
      id
      name
      customer_reference_code
      status
      ob_org_id
      total_invoice_amount 
      total_payment_amount
      total_balance_amount
      total_invoice_amount
    }
  }
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_CUSTOMER = async (org_id) => {
  let QUERY = `query MyQuery($org_id: uuid) {
  customers(where: { ob_org_id: {_eq: "${org_id}"}}) {
    id
    name
    customer_reference_code
    status
    ob_org_id
    total_invoice_amount
    total_payment_amount
    total_balance_amount
  }
}
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_CUSTOMER_BY_CUSTOMER_ORG_ID = async (customer_org_id) => {
  let QUERY = `query MyQuery($customer_org_id: uuid) {
  customers(where: { customer_org_id: {_eq: "${customer_org_id}"}}) {
    id
    name
    customer_reference_code
    status
    ob_org_id
  }
}
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const INSERT_CUSTOMER_RECEIPT = async (ICR) => {
  const QUERY = `
  mutation InsertCustomerReceipt($Objects: [customer_receipt_insert_input!]!) {
    insert_customer_receipt(objects: $Objects) {
      affected_rows
    }
  }
  `;
  const result = await nhost.graphql.request(QUERY, ICR);
  return result;
};

export const DELETE_ADDITIONAL_CHARGE = async (id) => {
  let QUERY = `
  mutation MyMutation ($id: uuid) {
    delete_customer_checkout_addititonal_charges(where: {id: {_eq: "${id}"}}) {
      affected_rows
    }
  }
  `;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const UPDATE_PRODUCTS = async (payload) => {
  let QUERY = `
  mutation MyMutation {
    update_products(where: {id: {_eq: "${payload?.id}"}}, _set: {qty_allocated: "${payload?.req_quantity}"}) {
      affected_rows
    }
  }
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ORDERS_DASHBOARD = async (payload) => {
  const { org_id, startDate, endDate } = payload;
  let QUERY = `query MyQuery {
    orders_aggregate(where: {ob_org_id: {_eq: "${org_id}"}, created_at: {_gte: "${startDate}", _lte: "${endDate}"}}) {
      aggregate {
        count
        sum {
          total_price
        }
        avg {
          total_price
        }
      }
      nodes {
        created_at
        total_price
        name
        created_at
        date
        status
        items
        sage_order_ref_no
        order_Type
        despatch_status
        order_status
        order_Unique_id
        customer_id
      }
    }
  }
  `;
  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_PRODUCT_BY_CATEGORIE = async (categorieId) => {
  let QUERY = `query MyQuery {
    products(where: {category: {id: {_eq: "${categorieId}"}}}) {
      id
      name 
    }
  }`;

  const { data } = await nhost.graphql.request(QUERY);
  return data;
};

export const GET_ORDER_BY_CATEGORIE = async (key) => {
  const QUERY = `
    query GetOrderItemsById($itemId: uuid!) {
    orders(where: {items: {_contains: [{id: $itemId}]}}) {
      order_Unique_id
      total_price
      items
      total_quantity
      created_at
    }
  }
  `;
  let variables = {
    itemId: key,
  };

  // Make the request with the constructed query
  const { data } = await nhost.graphql.request(QUERY, variables);
  return data;
};
