import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DateField, Popconfirm } from "@pankod/refine-antd";
import { useNavigation, useNotification } from "@pankod/refine-core";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "antd";

import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import {
  DELETE_FAVORITE_ITEM,
  GET_FAVOURITE_LIST_ITEMS_BY_FAVORITE_LIST_ID,
  MUTATE_MULTIPLE_FAVORITE_ITEMS,
} from "query";

export const ViewFavouriteListItems: React.FC<any> = () => {
  const { id, listId } = useParams();

  const { goBack } = useNavigation();

  const [newData, setNewData] = useState([]);

  const { open, close } = useNotification();

  const [PageData, setPageData] = useState({ limit: 100, pageNo: 1 });

  const [count, setCount] = useState<any>();

  const [maxPage, setMaxPage] = useState<any>();

  const [render, setRender] = useState<boolean>(false);

  const nextPage = (cur: any) => {
    setPageData({ ...PageData, pageNo: PageData?.pageNo + 1 });
  };
  const prevPage = (cur: any) => {
    setPageData({ ...PageData, pageNo: PageData?.pageNo - 1 });
  };

  const getInitData = async (cond: Object) => {
    try {
      const Data: any = await GET_FAVOURITE_LIST_ITEMS_BY_FAVORITE_LIST_ID(
        id,
        cond
      );

      setNewData(Data?.favorite_lists_items);
      setCount(Data?.favorite_lists_items_aggregate?.aggregate?.count);
      setMaxPage(
        Math.ceil(
          Data?.favorite_lists_items_aggregate?.aggregate?.count /
            PageData.limit
        )
      );
      setCount(Data?.favorite_lists_items_aggregate?.aggregate?.count);
    } catch (err) {
      console.log("Error =>", err);
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong: ${err}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);
    }
  };

  useEffect(() => {
    const cond = {
      object: {
        favorite_list_id: { _eq: id },
        product: { category_id: { _eq: listId } },
      },
      limit: PageData?.limit,
      offset: (PageData.pageNo - 1) * PageData.limit,
    };

    getInitData(cond);
  }, [PageData?.limit, PageData?.pageNo, render]);

  const [rowsChanged, setRowsChanged] = useState(false);

  const onDragEnd = (result: any) => {
    // handle drag and drop here
    if (!result.destination) {
      return;
    }

    setRowsChanged(true);

    const items = Array.from(newData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setNewData(items);
  };

  const deleteFavListItem = async (item: any) => {
    try {
      const result: any = await DELETE_FAVORITE_ITEM(item?.id);

      if (result?.data?.delete_favorite_lists_items_by_pk?.id) {
        setRender(true);
        open?.({
          key: "success",
          type: "success",
          message: "Success",
          description: `Product has been removed from the list `,
        });
        setTimeout(() => {
          close("success");
        }, 3000);
        setRender(false);
      }
    } catch (error) {
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);
    }
  };

  const savedData = async () => {
    try {
      const orderdData = newData?.map((e: any, i: any) => {
        return { ...e, order: i };
      });

      const changedQueryData = orderdData?.map((e: any, i: any) => {
        return {
          where: { id: { _eq: e?.id } },
          _set: { order: e?.order },
        };
      });

      const Data: any = await MUTATE_MULTIPLE_FAVORITE_ITEMS(id, {
        set: changedQueryData,
      });

      setNewData(
        Data?.update_favorite_lists_items_many?.map(
          (e: any, i: any) => e?.returning?.[0]
        )
      );
      open?.({
        key: "success",
        type: "success",
        message: "",
        description: `Order has been updated         `,
      });
      setTimeout(() => {
        close("success");
      }, 5000);
    } catch (error) {
      open?.({
        key: "error",
        type: "error",
        message: "",
        description: `Something Went Wrong ${error}`,
      });
      setTimeout(() => {
        close("error");
      }, 5000);
    }
  };
  return (
    <>
      <div onClick={() => goBack()} className="d-flex justify-content-start">
        <Button type="primary">
          <div className="d-flex justify-content-center align-items-center">
            <ArrowLeftOutlined className="product_back_button me-2" />
            Back
          </div>
        </Button>
      </div>
      <div>{/* <CategoriesAutoComplete/> */}</div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div style={{ float: "right" }}>
          {/* {rowsChanged ?  <Button type="primary" onClick={savedData}>Save</Button> : null} */}
          {rowsChanged ? (
            <Button
              type="primary"
              onClick={savedData}
              style={{ padding: "2px 10px", float: "right" }}
            >
              Save Order
            </Button>
          ) : null}
        </div>
        <table
          style={{
            borderCollapse: "separate",
            borderSpacing: "0 10px",
            borderBottomColor: "1px solid black",
          }}
          width="100%"
        >
          <thead>
            <tr style={{ padding: "5px" }}>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Id</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Name</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Active</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Order</th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>
                Created at
              </th>
              <th style={{ padding: "5px", background: "#f1f1f1" }}>Action</th>
            </tr>
          </thead>
          <Droppable droppableId="characters">
            {(provided) => (
              <tbody
                style={{ padding: "15px" }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {/* <table width="100%" > */}
                {newData?.map((item: any, index: any) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(item?.id)}
                    index={index}
                  >
                    {(provided) => (
                      <tr
                        style={{ padding: "15px" }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                      >
                        <td style={{ padding: "5px" }}>{item.uid}</td>
                        <td style={{ padding: "5px" }}>
                          {item?.product?.name}
                        </td>
                        <td style={{ padding: "5px" }}>
                          {item.is_active ? "true" : "false"}
                        </td>
                        <td style={{ padding: "5px" }}>
                          {item?.order != null ? String(item?.order) : null}
                        </td>
                        <td style={{ padding: "5px" }}>
                          <DateField value={item.created_at} format="LLL" />
                        </td>
                        <td style={{ padding: "5px" }}>
                          <Popconfirm
                            title="Delete?"
                            onConfirm={() => deleteFavListItem(item)}
                          >
                            <Button
                              style={{
                                color: "red",
                                outline: "none",
                                border: "none",
                              }}
                              icon={<DeleteOutlined />}
                            ></Button>
                          </Popconfirm>
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
                {/* </table> */}
              </tbody>
            )}
          </Droppable>
        </table>
        <div
          style={{
            display: "flex",
            gap: "1rem",
            padding: "10px",
            width: "95%",
            alignItems: "center",
            justifyContent: "flex-end",
            // background:"green"
          }}
        >
          <div>
            <Button
              onClick={() => prevPage(PageData?.pageNo)}
              disabled={PageData.pageNo <= 1}
            >
              Previous
            </Button>
            <Button
              onClick={() => nextPage(PageData?.pageNo)}
              disabled={PageData?.pageNo >= maxPage}
            >
              Next
            </Button>
          </div>
          <span>
            Page{" "}
            <strong>
              {PageData?.pageNo} of {maxPage}
            </strong>
          </span>

          <select
            value={PageData?.limit}
            onChange={(e) => {
              const value = e.target.value ? Number(e.target.value) : 100;
              setPageData({ ...PageData, limit: value });
            }}
          >
            {[100, 200, 300, 400, 500].map((size) => (
              <option key={size} value={size}>
                Show {size}
              </option>
            ))}
          </select>
        </div>
        {/* {rowsChanged ?  <Button type="primary" onClick={savedData}>Save</Button> : null} */}
      </DragDropContext>
    </>
  );
};
