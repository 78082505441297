import React, { useEffect, useState } from "react";

import {
  useLogin,
  useTranslate,
  useNavigation,
  useGetIdentity,
} from "@pankod/refine-core";

import {
  AntdLayout,
  Menu,
  MenuProps,
  Icons,
  useMenu,
} from "@pankod/refine-antd";
import {
  Title,
  Header,
  Footer,
  OrderBitLayout,
  OffLayoutArea,
} from "components/layout";
import { Routes, Route, Link } from "react-router-dom";
import { StarOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { CustomersList } from "./list";
import { CustomerGroupList } from "./customerGroup";
import { ViewOrderList } from "./viewOrder";
import { FavouriteList } from "./favourite";
import { ViewFavouriteListItems } from "./favourite/view";
import { ViewCategories } from "./favourite/categoriesView";
import { AuthPermission } from "utility/authPermission";
import { useMenuContext } from "contaxt/menuContext";
// import { CustomerGroupList } from './customerGroup';
const {
  BarcodeOutlined,
  AppstoreOutlined,
  DollarOutlined,
  TagOutlined,
  SkinOutlined,
  BlockOutlined,
  SettingTwoTone,
  UnorderedListOutlined,
  PercentageOutlined,
} = Icons;
const { Sider, Content } = AntdLayout;
const { SubMenu } = Menu;

const items2: MenuProps["items"] = [
  Icons.UserOutlined,
  Icons.LaptopOutlined,
  Icons.NotificationOutlined,
].map((icon, index) => {
  const key = String(index + 1);

  return {
    key: `sub${key}`,
    icon: React.createElement(icon),
    label: `subnav ${key}`,

    children: new Array(4).fill(null).map((_, j) => {
      const subKey = index * 4 + j + 1;
      return {
        key: subKey,
        label: `option${subKey}`,
      };
    }),
  };
});

export const CustomerPage: React.FC = () => {
  const { push } = useNavigation();
  const { menuItems, selectedKey, defaultOpenKeys } = useMenu();
  const { menuPermission } = useMenuContext();

  return (
    <AntdLayout style={{ minHeight: "90vh", flexDirection: "row" }}>
      <Sider width={200} className="site-layout-background">
        <h2 style={{ margin: "20px" }}>Customers</h2>
        <Menu
          theme="light"
          selectedKeys={[selectedKey]}
          defaultOpenKeys={defaultOpenKeys}
          mode="inline"
          onClick={({ key }) => {
            push(key as string);
          }}
        >
          <Menu.Item
            icon={<BarcodeOutlined />}
            key="/customers/customer"
            onClick={() => {
              push("/customers/customer");
            }}
            style={{
              fontWeight:
                selectedKey === "/customers/customer" ? "bold" : "normal",
            }}
          >
            Customers
          </Menu.Item>
          {/* <Menu.Item
                icon={<BarcodeOutlined />}
                    key="/customers/viewOrder"
                    onClick={() => {
                        push("/customers/viewOrder");
                    }}
                    style={{
                        fontWeight: (selectedKey === "/customers/viewOrder") ? "bold" : "normal",
                    }}>
                        View Order
            </Menu.Item> */}
          {!menuPermission && (
            <Menu.Item
              icon={<UsergroupAddOutlined />}
              key="/customers/customergroups"
              onClick={() => {
                push("/customers/customergroups");
              }}
              style={{
                fontWeight:
                  selectedKey === "/customers/customergroups"
                    ? "bold"
                    : "normal",
              }}
            >
              Customer Group
            </Menu.Item>
          )}

          <Menu.Item
            icon={<StarOutlined />}
            key="/customers/favourite"
            onClick={() => {
              push("/customers/favourite");
            }}
            style={{
              fontWeight:
                selectedKey === "/customers/favourite" ? "bold" : "normal",
            }}
          >
            Favourite
          </Menu.Item>
        </Menu>
      </Sider>
      <Content
        className="site-layout-background"
        style={{
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <Routes>
          <Route index element={<CustomersList />} />
          <Route path="customer" element={<CustomersList />} />
          <Route path="customergroups" element={<CustomerGroupList />} />
          <Route path="favourite" element={<FavouriteList />} />
          <Route path="favourite/:id" element={<ViewCategories />} />
          <Route
            path="favourite/:id/list/:listId"
            element={<ViewFavouriteListItems />}
          />
          <Route path="viewOrder" element={<ViewOrderList />} />
          <Route path="*" element={<div>Anything</div>} />
        </Routes>
      </Content>
    </AntdLayout>
  );
};
