import { Typography, Table } from "@pankod/refine-antd";
import React, { useEffect, useState } from "react";
import { Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useNotification } from "@pankod/refine-core";
import { GET_FRANCHISE_PRODUCT_LIST, DELETE_FRANCHISE_PRODUCT_LIST } from "query";

const { Text } = Typography;

type ViewFranchiseProps = {
  franchise_mapping_id: string | null;
  refreshTrigger?: number;
};

export const ViewFranchiseProducts: React.FC<ViewFranchiseProps> = ({
  franchise_mapping_id,
  refreshTrigger = 0,
}) => {
  const { open } = useNotification();
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchFranchiseProducts = async () => {
    if (!franchise_mapping_id) return;
    setLoading(true);
    try {
      const result: any = await GET_FRANCHISE_PRODUCT_LIST(
        franchise_mapping_id
      );
      if (result && result.franchise_product_list) {
        setProducts(result.franchise_product_list);
      } else {
        setProducts([]);
      }
    } catch (error) {
      console.error("Error fetching franchise products:", error);
      open({
        type: "error",
        message: "Failed to fetch franchise products",
      });
      setProducts([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (franchise_mapping_id) {
      fetchFranchiseProducts();
    }
  }, [franchise_mapping_id, refreshTrigger]);

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      const result: any = await DELETE_FRANCHISE_PRODUCT_LIST(id);
      
      if (result && result.data && result.data.update_franchise_product_list) {
        const affectedRows = result.data.update_franchise_product_list.affected_rows;
        if (affectedRows > 0) {
          open({
            type: "success",
            message: "Product removed successfully",
          });
          // Refresh the product list after successful deletion
          fetchFranchiseProducts();
        } else {
          open({
            type: "error",
            message: "Failed to remove product",
          });
        }
      } else {
        open({
          type: "error",
          message: "Failed to remove product",
        });
      }
    } catch (error) {
      console.error("Error removing product:", error);
      open({
        type: "error",
        message: "An error occurred while removing the product",
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "franchise_product_list_product",
      key: "name",
      render: (_: any, record: any) => {
        return (
          <Text>{record.franchise_product_list_product?.name || "N/A"}</Text>
        );
      },
    },
    {
      title: "Active",
      dataIndex: "is_active",
      key: "is_active",
      render: (_: any, record: any) => {
        return <Text>{record.is_active ? "true" : "false"}</Text>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <DeleteOutlined
            style={{ color: "#ff4d4f", cursor: "pointer" }}
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <div
        style={{
          marginBottom: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title level={4} style={{ margin: 0 }}>
          Franchise Products
        </Typography.Title>
      </div>

      <Table
        columns={columns}
        dataSource={products}
        loading={loading}
        rowKey="id"
        pagination={{ pageSize: 10 }}
        style={{ backgroundColor: "white", borderRadius: "8px" }}
      />
    </div>
  );
};
