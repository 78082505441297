import { DeleteOutlined, PictureOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Modal,
  Tabs,
  InputNumber,
  Checkbox,
  useSelect,
  Select,
} from "@pankod/refine-antd";
import { useGetIdentity, useNotification } from "@pankod/refine-core";
import { IOrg } from "interfaces";
import { useEffect, useState } from "react";
import { Upload, message } from "antd";
import { nhost } from "utility";
import type { UploadProps, MenuProps, FormInstance, InputRef } from "antd";
import { jsonConverter } from "components/helpers/jsonConverter";
import { v4 as uuidv4 } from "uuid";

type EditBannersProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeEditModal: () => void;
  editModalVisible: boolean;
  handleFormSubmitted: () => void;
  tableQueryResult: any;
};
const { TextArea } = Input;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const onChange = (key: string) => {};

export const EditBanner: React.FC<EditBannersProps> = ({
  modalProps,
  formProps,
  closeEditModal,
  editModalVisible,
  handleFormSubmitted,
  tableQueryResult,
}) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const userId = formProps?.initialValues?.id;
  const { open, close } = useNotification();

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc" }],
  });

  const isAdmin = user?.metadata?.role === "admin";

  const [textBoxModal, setTextBoxModal] = useState(false);
  const [urlList, setUrlList] = useState<any>([]);
  const [mobileUrlList, setMobileUrlList] = useState([] as any[]);
  const [selectedBannerType, setSelectedBannerType] = useState<any>(null);
  const [selectedBannerPage, setSelectedBannerPage] = useState(null);
  const [selectedLinkType, setSelectedLinkType] = useState<any>(null);
  const [selectedUrl, setSelectedUrl] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [headingShow, setHeadingShow] = useState({ signup: true, login: true });
  const [urlButtonShow, setURLButtonShow] = useState({
    signup: true,
    login: true,
  });
  const [selectedsidebarLocation, setSelectedsidebarLocation] =
    useState<any>(null);
  const handleActiveCheck = () => {
    setTextBoxModal(!textBoxModal);
  };
  const [loginItems, setLoginItems] = useState([{ id: uuidv4() }]);
  const [signupItems, setSignupItems] = useState([{ id: uuidv4() }]);

  const props: UploadProps = {
    beforeUpload: (file) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error("File size exceeds the limit (10MB).");
        return false;
      }
      return true;
    },
    name: "file",
    multiple: true,
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {},
  };

  useEffect(() => {
    const bannerImages = formProps?.initialValues?.image?.split(",");
    bannerImages && setUrlList([...bannerImages]);
    setMobileUrlList([formProps?.initialValues?.mobile_image]);
    setSelectedBannerType(formProps?.initialValues?.banner_props?.banner_type);
    setSelectedBannerPage(formProps?.initialValues?.banner_props?.banner_page);

    // Initialize login and signup items
    if (
      Array.isArray(formProps?.initialValues?.banner_props?.login) &&
      formProps?.initialValues?.banner_props?.login.length > 0
    ) {
      setLoginItems(
        formProps?.initialValues?.banner_props?.login.map((item: any) => ({
          ...item,
          id: uuidv4(),
        }))
      );
    } else {
      setLoginItems([{ id: uuidv4() }]);
    }

    if (
      Array.isArray(formProps?.initialValues?.banner_props?.signup) &&
      formProps?.initialValues?.banner_props?.signup.length > 0
    ) {
      setSignupItems(
        formProps?.initialValues?.banner_props?.signup.map((item: any) => ({
          ...item,
          id: uuidv4(),
        }))
      );
    } else {
      setSignupItems([{ id: uuidv4() }]);
    }

    // Handle signup tab data
    if (formProps?.initialValues?.banner_props?.signup?.[0]?.heading?.title) {
      handelHead();
      setHeadingShow((prev) => ({ ...prev, signup: false }));
    }

    if (formProps?.initialValues?.banner_props?.signup?.[0]?.link?.link_type) {
      handelUrlButton();
      setURLButtonShow((prev) => ({ ...prev, signup: false }));
    }

    // Handle login tab data
    if (formProps?.initialValues?.banner_props?.login?.[0]?.heading?.title) {
      handelHeadLogin();
      setHeadingShow((prev) => ({ ...prev, login: false }));
    }

    if (formProps?.initialValues?.banner_props?.login?.[0]?.link?.link_type) {
      handelUrlButtonLogin();
      setURLButtonShow((prev) => ({ ...prev, login: false }));
    }
  }, [formProps]);

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      {
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4");
        data.append("cloud_name", "dov89ugan");
        data.append("folder", "orderbit");
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data,
        })
          .then((resp) => resp.json())
          .then((data) => {
            setUrlList((prevUrlList: any[]) => {
              const newList = [...prevUrlList, data.secure_url];
              if (selectedBannerType === "Card") {
                if (newList.length > 3) {
                  return newList.slice(-3);
                }
                return newList;
              } else {
                if (newList.length > 2) {
                  return newList.slice(-2);
                }
                return newList;
              }
            });
          })
          .catch((err) => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const uploadMobileImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "bt5y9hp4");
      data.append("cloud_name", "dov89ugan");
      data.append("folder", "orderbit");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dov89ugan/image/upload",
        {
          method: "post",
          body: data,
        }
      );

      const result = await response.json();

      if (response.ok) {
        const transformedUrl = `https://res.cloudinary.com/dov89ugan/image/upload/w_450,h_222,c_fill/${result.public_id}.${result.format}`;
        setMobileUrlList((mobileUrlList) => [transformedUrl]);
        onSuccess("Ok");
      } else {
        throw new Error(result.error.message);
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  // const removeImage = () => {
  //   setUrlList([]);
  // };

  const removeImage = (indexToRemove: number) => {
    setUrlList((prevUrlList: any[]) =>
      prevUrlList.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeMobileImage = () => {
    setMobileUrlList([]);
  };

  const resetHeadingProps = (heading: any) => {
    Object.assign(heading, {
      heading_color: null,
      title: null,
    });
  };

  const resetLinkProps = (link: any) => {
    Object.assign(link, {
      link_color: null,
      link_text: null,
      link_text_color: null,
      link_type: null,
    });
  };

  const apidata = (e: any) => {
    // Process login items - filter out empty items and format properly
    const processedLoginItems = loginItems
      .map((item, index) => {
        const formValues = e.banner_props?.login?.[index] || {};

        // Skip empty items
        if (
          !formValues.heading?.title &&
          !formValues.description?.description &&
          !formValues.link?.link_text
        ) {
          return null;
        }

        return {
          link: {
            link_text: !urlButtonShow.login
              ? formValues.link?.link_text || "Click Me"
              : null,
            link_type: !urlButtonShow.login
              ? formValues.link?.link_type || "Button"
              : null,
            link_color: !urlButtonShow.login
              ? formValues.link?.link_color || "#080808"
              : null,
            link_text_color: !urlButtonShow.login
              ? formValues.link?.link_text_color || "#f5f5f5"
              : null,
          },
          heading: {
            title: !headingShow.login ? formValues.heading?.title || "" : null,
            heading_color: !headingShow.login
              ? formValues.heading?.heading_color || "#080808"
              : null,
          },
          description: {
            description: formValues.description?.description || "",
            description_color:
              formValues.description?.description_color || "#080808",
          },
          redirect_url: formValues.redirect_url || "category",
        };
      })
      .filter(Boolean); // Remove null items

    // Process signup items - filter out empty items and format properly
    const processedSignupItems = signupItems
      .map((item, index) => {
        const formValues = e.banner_props?.signup?.[index] || {};

        // Skip empty items
        if (
          !formValues.heading?.title &&
          !formValues.description?.description &&
          !formValues.link?.link_text
        ) {
          return null;
        }

        return {
          link: {
            link_text: !urlButtonShow.signup
              ? formValues.link?.link_text || "REGISTER"
              : null,
            link_type: !urlButtonShow.signup
              ? formValues.link?.link_type || "Button"
              : null,
            link_color: !urlButtonShow.signup
              ? formValues.link?.link_color || "#080808"
              : null,
            link_text_color: !urlButtonShow.signup
              ? formValues.link?.link_text_color || "#f5f5f5"
              : null,
          },
          heading: {
            title: !headingShow.signup ? formValues.heading?.title || "" : null,
            heading_color: !headingShow.signup
              ? formValues.heading?.heading_color || "#080808"
              : null,
          },
          description: {
            description: formValues.description?.description || "",
            description_color:
              formValues.description?.description_color || "#080808",
          },
          redirect_url: formValues.redirect_url || "signin",
        };
      })
      .filter(Boolean); // Remove null items

    // Create the updated banner props object
    const updatedBannerProps = {
      banner_page: e.banner_props?.banner_page || "Home",
      banner_type: e.banner_props?.banner_type || "Main",
      side_bar_location: e.banner_props?.side_bar_location || null,
      special_banner_location: e.banner_props?.special_banner_location || null,
      login:
        processedLoginItems.length > 0
          ? processedLoginItems
          : [
              {
                description: { description: "", description_color: "#080808" },
                heading: null,
                link: null,
                redirect_url: "category",
              },
            ],
      signup:
        processedSignupItems.length > 0
          ? processedSignupItems
          : [
              {
                description: { description: "", description_color: "#080808" },
                heading: null,
                link: null,
                redirect_url: "signin",
              },
            ],
    };

    // Construct the mutation query
    const MUTATIONAPI = `
      mutation {
        update_banners(_set: {  
          view_order: ${e.view_order || 0}, 
          active: ${e.active === undefined ? true : e.active},
          image: ${urlList.length > 0 ? `"${urlList.join(",")}"` : null},
          mobile_image: ${
            mobileUrlList.length > 0 ? `"${mobileUrlList[0]}"` : null
          },
          ob_org_id: "${isAdmin ? e.ob_org_id : user?.metadata?.org_id}", 
          banner_props: ${jsonConverter(updatedBannerProps)}
        }
        where: { id: { _eq: "${userId}" } }) {
          affected_rows
        }
      }
    `;

    // Execute the mutation
    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI);
      if (data) {
        open?.({
          key: "success",
          type: "success",
          message: "",
          description: `Banner Successfully Updated`,
        });
        setTimeout(() => {
          close?.("success");
        }, 4000);
        closeEditModal();
        setTimeout(() => {
          tableQueryResult?.refetch();
        }, 300);
        return data;
      }
    };
    imageSubmit();
  };

  const handleBannerPageChange = (value: any) => {
    setSelectedBannerPage(value);
  };

  const handleBannerTypeChange = (value: any) => {
    setSelectedBannerType(value);
  };

  const selectBannerPageOptions = [
    { label: "Home", value: "Home" },
    { label: "Contact", value: "Contact" },
    { label: "About", value: "About" },
    { label: "FAQ", value: "FAQ" },
  ];

  const selectHomeOptions = [
    { label: "Main", value: "Main" },
    { label: "Special Banner", value: "Special Banner" },
    { label: "Card", value: "Card" },
    { label: "Side bar", value: "Side bar" },
  ];

  const selectOtherPageOptions = [
    { label: "Main", value: "Main" },
    { label: "Side bar", value: "Side bar" },
  ];

  const selectOptions =
    selectedBannerPage == "Home" ? selectHomeOptions : selectOtherPageOptions;

  // useEffect(() => {
  //   if (selectedBannerPage === "Contact" || selectedBannerPage === "About") {

  //     setSelectedBannerType("Main");
  //     formProps?.form?.setFieldsValue({[`banner_props.banner_type`]: "Main"});
  //   }
  // }, [selectedBannerPage]);

  const handleLinkTypeChange = (value: any) => {
    setSelectedLinkType(value);
  };

  const linkSelectOptions = [
    { label: "Button", value: "Button" },
    { label: "Link", value: "Link" },
  ];

  const handleUrlChange = (value: any) => {
    setSelectedUrl(value);
  };

  const urlSelectOptions = [
    { label: "Category", value: "category" },
    { label: "Signin", value: "signin" },
    { label: "Register", value: "register" },
    { label: "Favourites", value: "my-account/favourites" },
    { label: "Orders", value: "my-account/orders" },
  ];

  const handleLocationChange = (value: any) => {
    setSelectedLocation(value);
  };

  const handlesidebarLocationChange = (value: any) => {
    setSelectedsidebarLocation(value);
  };

  const locationSelectOptions = [
    { label: "Below Main banner", value: "Below Main banner" },
    { label: "Below Cards", value: "Below Cards" },
    { label: "Below Feature Product", value: "Below Feature Product" },
    { label: "Below Category", value: "Below Category" },
    { label: "Above Footer", value: "Above Footer" },
  ];

  const sidebarLocationOptions = [
    { label: "Left", value: "Left" },
    { label: "Right", value: "Right" },
  ];

  const handelHead = () => {
    setHeadingShow((prevState) => ({
      ...prevState,
      signup: !prevState.signup,
    }));
  };

  const handelHeadLogin = () => {
    setHeadingShow((prevState) => ({
      ...prevState,
      login: !prevState.login,
    }));
  };

  const handelUrlButton = () => {
    setURLButtonShow((prevState) => ({
      ...prevState,
      signup: !prevState.signup,
    }));
  };

  const handelUrlButtonLogin = () => {
    setURLButtonShow((prevState) => ({
      ...prevState,
      login: !prevState.login,
    }));
  };

  const addLoginItem = () => {
    setLoginItems([...loginItems, { id: uuidv4() }]);
  };

  const removeLoginItem = (id: string) => {
    if (loginItems.length > 1) {
      setLoginItems(loginItems.filter((item) => item.id !== id));
    } else {
      message.error("At least one login item is required");
    }
  };

  const addSignupItem = () => {
    setSignupItems([...signupItems, { id: uuidv4() }]);
  };

  const removeSignupItem = (id: string) => {
    if (signupItems.length > 1) {
      setSignupItems(signupItems.filter((item) => item.id !== id));
    } else {
      message.error("At least one signup item is required");
    }
  };

  const onFinish = (values: any) => {
    apidata(values);
    // handleFormSubmitted();
  };

  return (
    <Modal
      {...modalProps}
      title="Edit Banner"
      onCancel={closeEditModal}
      visible={editModalVisible}
      className="edit_banner"
    >
      <Form {...formProps} onFinish={onFinish} size="large" layout="vertical">
        {isAdmin && (
          <Form.Item
            name="ob_org_id"
            label="Organization"
            rules={[
              {
                required: true,
                message: "Please Select Organization!",
              },
            ]}
            initialValue={user?.metadata?.org_id}
          >
            <Select {...OBOrgSelectProps} />
          </Form.Item>
        )}
        <Form.Item
          name={["banner_props", "banner_page"]}
          label="Banner Page"
          rules={[{ required: true, message: "Please Select Banner Page!" }]}
        >
          <Select onChange={handleBannerPageChange} value={selectedBannerPage}>
            {selectBannerPageOptions.map((option: any) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <div className="banner_props">
          <Form.Item
            name={["banner_props", "banner_type"]}
            label="Banner Type"
            rules={[{ required: true, message: "Please Select Banner Type!" }]}
          >
            <Select
              onChange={handleBannerTypeChange}
              value={selectedBannerType}
            >
              {selectOptions?.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {selectedBannerType != "Special Banner" ? (
            <>
              {selectedBannerType == "Side bar" ? (
                <>
                  <Form.Item
                    name={["banner_props", "side_bar_location"]}
                    label="Location"
                    rules={[
                      {
                        required: true,
                        message: "Please Select Side bar Location!",
                      },
                    ]}
                  >
                    <Select onChange={handleLocationChange}>
                      {sidebarLocationOptions.map((option: any) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Form.Item
                name={["banner_props", "special_banner_location"]}
                label="Location"
                rules={[
                  { required: true, message: "Please Select Banner Location!" },
                ]}
              >
                <Select
                  onChange={handleLocationChange}
                  value={selectedLocation}
                >
                  {locationSelectOptions.map((option: any) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </div>
        <div className="upload_images">
          <Form.Item
            label={
              selectedBannerType == "Side bar" ? (
                <div>
                  Upload Desktop Banner Image{" "}
                  <pre>
                    (When uploading an image, please ensure it has a{" "}
                    <strong> 2:3 </strong> aspect ratio for the best viewing
                    experience)
                  </pre>
                </div>
              ) : (
                "Upload Dashbord Banner Image"
              )
            }
            name="image"
            rules={[{ required: true, message: "Please Select Image!" }]}
          >
            <div className="Image_div">
              <div>
                <Dragger
                  showUploadList={false}
                  {...props}
                  name="image"
                  customRequest={uploadImages}
                  accept="image/*"
                >
                  <p className="ant-upload-drag-icon">
                    <PictureOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Drag image file here to upload(or Click)
                  </p>
                </Dragger>
              </div>
              <div className="category_img_div">
                <h1 style={{ fontSize: "14px" }}>
                  Uploaded image will be displayed here
                </h1>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                  }}
                >
                  {urlList?.map((url: any, index: any) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <img className="category_img" src={url} />
                        {url ? (
                          <span>
                            <DeleteOutlined
                              onClick={() => removeImage(index)}
                            />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </Form.Item>
          {selectedBannerType == "Main" && (
            <Form.Item
              label={
                <div>
                  Upload Mobile Banner Image{" "}
                  <pre>
                    (When uploading an image, please ensure it has a{" "}
                    <strong> 450 x 222 </strong> pixel for the best viewing
                    experience)
                  </pre>
                </div>
              }
              name="mobile_image"
              // rules={[{ required: true, message: 'Please Select Image!' }]}
            >
              <div className="Image_div">
                <div>
                  <Dragger
                    showUploadList={false}
                    {...props}
                    name="image"
                    customRequest={uploadMobileImages}
                    accept="image/*"
                  >
                    <p className="ant-upload-drag-icon">
                      <PictureOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drag image file here to upload(or Click)
                    </p>
                  </Dragger>
                </div>
                <div className="category_img_div">
                  <h1 style={{ fontSize: "14px" }}>
                    Uploaded image will be displayed here
                  </h1>
                  {mobileUrlList?.map((url: any) => {
                    return (
                      <div>
                        <img className="category_img" src={url} />
                        {url ? (
                          <span>
                            <DeleteOutlined onClick={removeMobileImage} />
                          </span>
                        ) : (
                          <></>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Form.Item>
          )}
        </div>
        <Tabs>
          <TabPane tab="Signup" key={1}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "16px",
              }}
            >
              <button
                type="button"
                onClick={addSignupItem}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#1890ff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Add Signup Item
              </button>
            </div>

            {signupItems.map((item, index) => (
              <div
                key={item.id}
                style={{
                  border: "1px solid #f0f0f0",
                  padding: "16px",
                  marginBottom: "16px",
                  borderRadius: "4px",
                  position: "relative",
                }}
              >
                {signupItems.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeSignupItem(item.id)}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      background: "#ff4d4f",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </button>
                )}

                <div className="heading">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                    aria-disabled={headingShow.signup}
                  >
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "heading",
                        "title",
                      ]}
                      label={
                        <span
                          style={{ color: headingShow.signup ? "gray" : "" }}
                        >
                          Heading
                        </span>
                      }
                      rules={[
                        {
                          required: headingShow.signup ? false : true,
                          message: "Please Enter Heading!",
                        },
                      ]}
                      style={{ width: "85%" }}
                    >
                      <TextArea rows={1} disabled={headingShow.signup} />
                    </Form.Item>
                    <Form.Item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-end",
                      }}
                    >
                      <Checkbox
                        checked={headingShow.signup ? false : true}
                        onClick={() => handelHead()}
                      >
                        Show
                      </Checkbox>
                    </Form.Item>
                  </div>

                  <Form.Item
                    name={[
                      "banner_props",
                      "signup",
                      index,
                      "heading",
                      "heading_color",
                    ]}
                    label={
                      <span style={{ color: headingShow.signup ? "gray" : "" }}>
                        Color
                      </span>
                    }
                    initialValue={headingShow.signup ? "#080808" : "#808080"}
                  >
                    <Input type="color" disabled={headingShow.signup} />
                  </Form.Item>
                </div>

                {selectedBannerType != "Card" ? (
                  <div className="description">
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "description",
                        "description",
                      ]}
                      label="Description"
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "description",
                        "description_color",
                      ]}
                      label="Color"
                      initialValue={"#080808"}
                    >
                      <Input type="color" />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}

                {selectedBannerPage == "Home" ? (
                  <div className="button">
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "link",
                        "link_type",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.signup ? "gray" : "" }}
                        >
                          Link Type
                        </span>
                      }
                      rules={[
                        {
                          required: urlButtonShow.signup ? false : true,
                          message: "Please Select Link Type!",
                        },
                      ]}
                    >
                      <Select
                        onChange={handleLinkTypeChange}
                        value={selectedLinkType}
                        disabled={urlButtonShow.signup}
                      >
                        {linkSelectOptions.map((option: any) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "link",
                        "link_text",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.signup ? "gray" : "" }}
                        >
                          Link Name
                        </span>
                      }
                      rules={[
                        {
                          required: urlButtonShow.signup ? false : true,
                          message: "Please Enter Link Name!",
                        },
                      ]}
                    >
                      <TextArea rows={1} disabled={urlButtonShow.signup} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "link",
                        "link_color",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.signup ? "gray" : "" }}
                        >
                          Color
                        </span>
                      }
                      initialValue={"#080808"}
                    >
                      <Input type="color" disabled={urlButtonShow.signup} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "link",
                        "link_text_color",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.signup ? "gray" : "" }}
                        >
                          Text Color
                        </span>
                      }
                      initialValue={"#f5f5f5"}
                    >
                      <Input type="color" disabled={urlButtonShow.signup} />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}

                <div className="others">
                  <div>
                    {selectedBannerPage == "Home" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          name={[
                            "banner_props",
                            "signup",
                            index,
                            "redirect_url",
                          ]}
                          label={
                            <span
                              style={{
                                color: urlButtonShow.signup ? "gray" : "",
                              }}
                            >
                              Url
                            </span>
                          }
                          style={{ color: "gray", width: "100%" }}
                          rules={[
                            {
                              required: urlButtonShow.signup ? false : true,
                              message: "Please Select Link Url!",
                            },
                          ]}
                        >
                          <Select
                            onChange={handleUrlChange}
                            value={selectedUrl}
                            disabled={urlButtonShow.signup}
                          >
                            {urlSelectOptions.map((option: any) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-end",
                          }}
                        >
                          <Checkbox
                            checked={urlButtonShow.signup ? false : true}
                            onClick={() => handelUrlButton()}
                          >
                            {" "}
                            Show{" "}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <Form.Item
                    label=""
                    name="active"
                    style={{ display: "flex", alignSelf: "end" }}
                    initialValue={true}
                    valuePropName="checked"
                  >
                    <Checkbox> Active </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="view_order"
                    label="View Order"
                    rules={[
                      { required: true, message: "Please input View Order!" },
                    ]}
                  >
                    <InputNumber
                      defaultValue="0"
                      style={{ width: "100%" }}
                      name="view_order"
                    />
                  </Form.Item> */}
                </div>
              </div>
            ))}
          </TabPane>
          <TabPane tab="Login" key={2}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "16px",
              }}
            >
              <button
                type="button"
                onClick={addLoginItem}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#1890ff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Add Login Item
              </button>
            </div>

            {loginItems.map((item, index) => (
              <div
                key={item.id}
                style={{
                  border: "1px solid #f0f0f0",
                  padding: "16px",
                  marginBottom: "16px",
                  borderRadius: "4px",
                  position: "relative",
                }}
              >
                {loginItems.length > 1 && (
                  <button
                    type="button"
                    onClick={() => removeLoginItem(item.id)}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "8px",
                      background: "#ff4d4f",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      padding: "4px 8px",
                      cursor: "pointer",
                    }}
                  >
                    Remove
                  </button>
                )}

                <div className="heading">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                    aria-disabled={headingShow.login}
                  >
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "heading",
                        "title",
                      ]}
                      label={
                        <span
                          style={{ color: headingShow.login ? "gray" : "" }}
                        >
                          Heading
                        </span>
                      }
                      rules={[
                        {
                          required: headingShow.login ? false : true,
                          message: "Please Enter Heading!",
                        },
                      ]}
                      style={{ width: "85%" }}
                    >
                      <TextArea rows={1} disabled={headingShow.login} />
                    </Form.Item>
                    <Form.Item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        alignSelf: "flex-end",
                      }}
                    >
                      <Checkbox
                        checked={headingShow.login ? false : true}
                        onClick={() => handelHeadLogin()}
                      >
                        Show
                      </Checkbox>
                    </Form.Item>
                  </div>

                  <Form.Item
                    name={[
                      "banner_props",
                      "login",
                      index,
                      "heading",
                      "heading_color",
                    ]}
                    label={
                      <span style={{ color: headingShow.login ? "gray" : "" }}>
                        Color
                      </span>
                    }
                    initialValue={headingShow.login ? "#080808" : "#808080"}
                  >
                    <Input type="color" disabled={headingShow.login} />
                  </Form.Item>
                </div>

                {selectedBannerType != "Card" ? (
                  <div className="description">
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "description",
                        "description",
                      ]}
                      label="Description"
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "description",
                        "description_color",
                      ]}
                      label="Color"
                      initialValue={"#080808"}
                    >
                      <Input type="color" />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}

                {selectedBannerPage == "Home" ? (
                  <div className="button">
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "link",
                        "link_type",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.login ? "gray" : "" }}
                        >
                          Link Type
                        </span>
                      }
                      rules={[
                        {
                          required: urlButtonShow.login ? false : true,
                          message: "Please Select Link Type!",
                        },
                      ]}
                    >
                      <Select
                        onChange={handleLinkTypeChange}
                        value={selectedLinkType}
                        disabled={urlButtonShow.login}
                      >
                        {linkSelectOptions.map((option: any) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "link",
                        "link_text",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.login ? "gray" : "" }}
                        >
                          Link Name
                        </span>
                      }
                      rules={[
                        {
                          required: urlButtonShow.login ? false : true,
                          message: "Please Enter Link Name!",
                        },
                      ]}
                    >
                      <TextArea rows={1} disabled={urlButtonShow.login} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "link",
                        "link_color",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.login ? "gray" : "" }}
                        >
                          Color
                        </span>
                      }
                      initialValue={"#080808"}
                    >
                      <Input type="color" disabled={urlButtonShow.login} />
                    </Form.Item>
                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "link",
                        "link_text_color",
                      ]}
                      label={
                        <span
                          style={{ color: urlButtonShow.login ? "gray" : "" }}
                        >
                          Text Color
                        </span>
                      }
                      initialValue={"#f5f5f5"}
                    >
                      <Input type="color" disabled={urlButtonShow.login} />
                    </Form.Item>
                  </div>
                ) : (
                  <></>
                )}

                <div className="others">
                  <div>
                    {selectedBannerPage == "Home" ? (
                      <div
                        style={{
                          display: "flex",
                          justifyItems: "center",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <Form.Item
                          name={[
                            "banner_props",
                            "login",
                            index,
                            "redirect_url",
                          ]}
                          label={
                            <span
                              style={{
                                color: urlButtonShow.login ? "gray" : "",
                              }}
                            >
                              Url
                            </span>
                          }
                          style={{ color: "gray", width: "100%" }}
                          rules={[
                            {
                              required: urlButtonShow.login ? false : true,
                              message: "Please Select Link Url!",
                            },
                          ]}
                        >
                          <Select
                            onChange={handleUrlChange}
                            value={selectedUrl}
                            disabled={urlButtonShow.login}
                          >
                            {urlSelectOptions.map((option: any) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          style={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-end",
                          }}
                        >
                          <Checkbox
                            checked={urlButtonShow.login ? false : true}
                            onClick={() => handelUrlButtonLogin()}
                          >
                            {" "}
                            Show{" "}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  {/* <Form.Item
                    label=""
                    name="active"
                    style={{ display: "flex", alignSelf: "end" }}
                    initialValue={true}
                    valuePropName="checked"
                  >
                    <Checkbox> Active </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="view_order"
                    label="View Order"
                    rules={[
                      { required: true, message: "Please input View Order!" },
                    ]}
                  >
                    <InputNumber
                      defaultValue="0"
                      style={{ width: "100%" }}
                      name="view_order"
                    />
                  </Form.Item> */}
                </div>

                <Form.Item name="created_by" initialValue={user?.id}>
                  <Input type="hidden" />
                </Form.Item>
              </div>
            ))}
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};
