import { Form, Input, Typography } from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import React, { useEffect, useState } from "react";
import { Select, Button, Modal, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNotification } from "@pankod/refine-core";
import { GET_CUSTOMER_BY_CUSTOMER_ORG_ID, INSERT_FRANCHISE } from "query";

const { Text } = Typography;

type CreateFranchiseProps = {
  customerOrgId: string | null;
  onSuccess?: () => void;
};

export const CreateFranchise: React.FC<CreateFranchiseProps> = ({
  customerOrgId,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { data: user } = useGetIdentity();
  const { open } = useNotification();
  const [model, setModel] = useState(false);
  const [customer, setCustomer] = useState<any>({});

  const getCustomerId = async () => {
    try {
      const getCustomerId: any = await GET_CUSTOMER_BY_CUSTOMER_ORG_ID(customerOrgId);
      const custmr = getCustomerId?.customers[0];
      custmr && setCustomer(custmr);
    } catch (error) {
      console.error("Error fetching franchise data:", error);
    }
  };

  useEffect(() => {
    getCustomerId();
  }, []);

  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();

      const result = await INSERT_FRANCHISE({
        Object: {
          name: values.name,
          customer_id: customer?.id || null,
          customer_group_id: null,
          ob_org_id: user?.metadata?.org_id,
          description: values?.description || "",
          created_by: user?.id,
          is_active: true,
        },
      }).then(() => {
        open({
          type: "success",
          message: "Franchise created successfully!",
        });
        setModel(false);
        form.resetFields();
        // Call the onSuccess callback to refresh the franchise list
        if (onSuccess) {
          onSuccess();
        }
      });
    } catch (error) {
      open({
        type: "error",
        message: "Failed to create franchise",
      });
    }
  };

  return (
    <>
      <Modal
        title="Create Franchise"
        onCancel={() => setModel(false)}
        visible={model}
        onOk={handleFormSubmit}
        okText="Create"
      >
        <Form form={form} layout="vertical" autoComplete="off">
          <Form.Item
            label="Franchise Name"
            name="name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item label="Customer">
            <Select
              placeholder="Select a customer"
              defaultValue={customer?.name}
              disabled
              style={{ width: "100%" }}
              //   value={customer?.id}
            //   onChange={(value) => setCustomerId(value)}
            >
              {/* Populate customer options dynamically */}
              {/* <Select.Option value="customer1">Customer 1</Select.Option>
              <Select.Option value="customer2">Customer 2</Select.Option> */}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea placeholder="Enter a description (optional)" />
          </Form.Item>
        </Form>
      </Modal>

      <Space style={{ marginTop: "15px" }}>
        <Button type="primary" onClick={() => setModel(true)}>
          <PlusOutlined style={{ marginRight: "5px" }} />
          Create Franchise
        </Button>
      </Space>
    </>
  );
};
