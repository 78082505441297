import * as React from "react";
import { Table, Space, Button, Popconfirm, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import { GET_CUSTOMER_BY_CUSTOMER_ORG_ID, GET_FRANCHISE, GET_FRANCHISE_BY_CUSTOMER_ID, DELETE_FRANCHISE_BY_CUSTOMER_ID } from "query";
import { useGetIdentity } from "@pankod/refine-core";
import { EyeOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import AddTypeHead from "./add";
import { ViewFranchiseProducts } from "./view";
import { useNotification } from "@pankod/refine-core";

interface FranchiseType {
  key: React.Key;
  id: string;
  franchise_name: string;
  customer_id: string;
  group_name: string;
}

interface AddTypeHeadProps {
  Id: string;
  refetch: () => void;
  Tablerefetch: () => void;
}

interface FranchiseResponse {
  id: string;
  name: string;
  customer_id: string;
  group_name: string;
}

const handleRefetch = () => {
  console.log("Refetching data...");
  // Add logic to refetch the data here
};

const handleTableRefetch = () => {
  console.log("Refreshing table...");
  // Add logic to refresh the table here
};

type FranchiseProps = {
  org_id: any;
  customer_org_id: any;
  customer_group_id: any;
};

export const Franchise: React.FC<FranchiseProps> = ({
  org_id,
  customer_group_id,
  customer_org_id,
}) => {
  const { data: user } = useGetIdentity();
  const userId = user?.metadata?.org_id;
  const [franchiseData, setFranchiseData] = React.useState<FranchiseType[]>([]);
  const [selectedFranchiseId, setSelectedFranchiseId] = useState<string | null>(null);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const { open } = useNotification();

  const refreshFranchiseList = () => {
    setRefreshTrigger(prev => prev + 1);
  };

  const getFranchise = async () => {
    try {
      const getCustomerId: any = await GET_CUSTOMER_BY_CUSTOMER_ORG_ID(customer_org_id);
      const customerId = getCustomerId?.customers[0]?.id;
      const response: any = customerId && await GET_FRANCHISE_BY_CUSTOMER_ID(customerId);
      if (response?.franchise_customer_customer_group_mappings) {
        const mappedData =
          response.franchise_customer_customer_group_mappings.map(
            (item: FranchiseResponse) => ({
              key: item.id,
              id: item.id,
              franchise_name: item.name,
              customer_id: item.customer_id,
              group_name: item.group_name,
            })
          );
        setFranchiseData(mappedData);
      } else {
        console.warn("No franchise data found");
        setFranchiseData([]);
      }
    } catch (error) {
      console.error("Error fetching franchise data:", error);
    }
  };

  useEffect(() => {
    getFranchise();
  }, [refreshTrigger]);

  const columns: ColumnsType<FranchiseType> = [
    {
      title: "Franchise Name",
      dataIndex: "franchise_name",
      key: "franchise_name",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <AddTypeHead
            Id={record.id}
            refetch={refreshFranchiseList}
            Tablerefetch={refreshFranchiseList}
          />
          <Button
            type="primary"
            style={{
              backgroundColor: "green",
              borderColor: "green",
              color: "white",
            }}
            icon={<EyeOutlined />}
            onClick={() => {
              setSelectedFranchiseId(record.id);
              setViewModalVisible(true);
            }}
          >
            View
          </Button>
          <Popconfirm
            title="Are you sure you want to delete this record?"
            onConfirm={async () => {
              try {
                await DELETE_FRANCHISE_BY_CUSTOMER_ID(record.id);
                open({
                  type: "success",
                  message: "Franchise deleted successfully",
                });
                refreshFranchiseList();
              } catch (error) {
                open({
                  type: "error",
                  message: "Error deleting franchise",
                });
              }
            }}
          >
            <Button
              style={{
                backgroundColor: "red",
                borderColor: "red",
                color: "white",
              }}
              icon={<DeleteOutlined />}
            >
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Modal
        visible={viewModalVisible}
        onCancel={() => setViewModalVisible(false)}
        footer={null}
        width={800}
      >
        {selectedFranchiseId && (
          <ViewFranchiseProducts franchise_mapping_id={selectedFranchiseId} />
        )}
      </Modal>
      
      <Table
        columns={columns}
        expandable={{
          // expandedRowRender: (record: FranchiseType) => (
          //   <div style={{ padding: 16, backgroundColor: "#fafafa" }}>
          //     Franchise Name: {record.franchise_name}
          //   </div>
          // ),
          rowExpandable: (record: FranchiseType) => !!record.franchise_name,
        }}
        dataSource={franchiseData}
        bordered
      />
    </>
  );
};
