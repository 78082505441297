import {
  Form,
  Input,
  ModalProps,
  FormProps,
  Tabs,
  Modal,
  InputNumber,
  Checkbox,
  useSelect,
  Select,
} from "@pankod/refine-antd";
import { useGetIdentity } from "@pankod/refine-core";
import React, { useState, useEffect } from "react";
import { IOrg } from "interfaces";
import { nhost } from "utility/nhost";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  PictureOutlined,
} from "@ant-design/icons";
import { UploadProps, Upload, DatePicker, message } from "antd";
import "../../store/store.css";
import { jsonConverter } from "components/helpers/jsonConverter";
import { v4 as uuidv4 } from "uuid";

type CreateBannersProps = {
  modalProps: ModalProps;
  formProps: FormProps;
  closeCreateModal: () => void;
  createModalVisible: boolean;
  handleFormSubmitted: () => void;
  tableQueryResult: any;
  viewOrderMax: any;
};
const { TextArea } = Input;
const { Dragger } = Upload;
const { TabPane } = Tabs;

const onChange = (key: string) => {};

export const CreateBanner: React.FC<CreateBannersProps> = ({
  modalProps,
  formProps,
  closeCreateModal,
  createModalVisible,
  handleFormSubmitted,
  tableQueryResult,
  viewOrderMax,
}) => {
  const { data: user } = useGetIdentity();

  const { selectProps: OBOrgSelectProps } = useSelect<IOrg>({
    resource: "ob_orgs",
    fetchSize: 1000,
    optionLabel: "name",
    optionValue: "id",
    metaData: {
      fields: ["id", "name"],
    },
    sort: [{ field: "name", order: "asc" }],
  });

  const isAdmin = user?.metadata?.role === "admin";

  const [textBoxModal, setTextBoxModal] = useState(false);
  const [urlList, setUrlList] = useState([] as any[]);
  const [mobileUrlList, setMobileUrlList] = useState([] as any[]);
  const [imageList, setImageList] = useState([] as any[]);
  const [closemodel, setCloseModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedBannerType, setSelectedBannerType] = useState(null);
  const [selectedBannerPage, setSelectedBannerPage] = useState(null);
  const [selectedLinkType, setSelectedLinkType] = useState(null);
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [headingShow, setHeadingShow] = useState({
    login: false,
    signup: false,
  });
  const [urlButtonShow, setURLButtonShow] = useState({
    login: false,
    signup: false,
  });
  const [loginItems, setLoginItems] = useState([{ id: uuidv4() }]);
  const [signupItems, setSignupItems] = useState([{ id: uuidv4() }]);

  const props: UploadProps = {
    beforeUpload: (file, fileList) => {
      const maxSizeInBytes = 10 * 1024 * 1024; // 10MB
      if (file.size > maxSizeInBytes) {
        message.error("File size exceeds the limit (10MB).");
        return false;
      }
      return true;
    },
    name: "file",
    multiple: true,
    onChange(info) {
      let data = [];
      for (const file of info.fileList) {
        data.push(file);
      }
      setImageList(data);
    },
    onDrop(e) {},
  };

  useEffect(() => {
    formProps?.form?.setFieldsValue({ [`view_order`]: ++viewOrderMax });
  }, [viewOrderMax]);

  const uploadImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      {
        const data = new FormData();
        data.append("file", file);
        data.append("upload_preset", "bt5y9hp4");
        data.append("cloud_name", "dov89ugan");
        data.append("folder", "orderbit");
        fetch(" https://api.cloudinary.com/v1_1/dov89ugan/image/upload", {
          method: "post",
          body: data,
        })
          .then((resp) => resp.json())
          .then((data) => {
            setUrlList((prevUrlList) => {
              const newList = [...prevUrlList, data.secure_url];
              // For Card banner type, allow up to 3 images
              if (selectedBannerType === "Card") {
                // Keep only the 3 most recent images if more than 3
                if (newList.length > 3) {
                  return newList.slice(-3);
                }
                return newList;
              } else {
                // For other banner types, keep only the 2 most recent images
                if (newList.length > 2) {
                  return newList.slice(-2);
                }
                return newList;
              }
            });
          })
          .catch((err) => console.log(err));
        onSuccess("Ok");
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  const uploadMobileImages = async (options: any) => {
    const { onSuccess, onError, file } = options;
    try {
      const data = new FormData();
      data.append("file", file);
      data.append("upload_preset", "bt5y9hp4");
      data.append("cloud_name", "dov89ugan");
      data.append("folder", "orderbit");

      const response = await fetch(
        "https://api.cloudinary.com/v1_1/dov89ugan/image/upload",
        {
          method: "post",
          body: data,
        }
      );

      const result = await response.json();

      if (response.ok) {
        const transformedUrl = `https://res.cloudinary.com/dov89ugan/image/upload/w_450,h_222,c_fill/${result.public_id}.${result.format}`;
        setMobileUrlList((mobileUrlList) => [transformedUrl]);
        onSuccess("Ok");
      } else {
        throw new Error(result.error.message);
      }
    } catch (err) {
      const error = new Error("Some error");
      onError({ err });
    }
  };

  // const removeImage = () => {
  //   setUrlList([]);
  // };

  const removeImage = (indexToRemove: number) => {
    setUrlList((prevUrlList) =>
      prevUrlList.filter((_, index) => index !== indexToRemove)
    );
  };

  const removeMobileImage = () => {
    setMobileUrlList([]);
  };

  const handleActiveCheck = () => {
    setTextBoxModal(!textBoxModal);
  };

  const resetHeadingProps = (heading: any) => {
    Object.assign(heading, {
      heading_color: null,
      title: null,
    });
  };

  const resetLinkProps = (link: any) => {
    Object.assign(link, {
      link_color: null,
      link_text: null,
      link_text_color: null,
      link_type: null,
    });
  };

  const apidata = (e: any) => {
    if (e?.banner_props?.signup.redirect_url == undefined) {
      e.banner_props.signup.redirect_url = "";
    }

    if (headingShow.login) {
      if (e?.banner_props?.login) {
        resetHeadingProps(e.banner_props.login.heading);
      }
    }

    if (urlButtonShow.login) {
      if (e?.banner_props?.login) {
        resetLinkProps(e.banner_props.login.link);
        Object.assign(e.banner_props.login, {
          redirect_url: null,
        });
      }
    }

    if (headingShow.signup) {
      if (e?.banner_props?.signup) {
        resetHeadingProps(e.banner_props.signup.heading);
      }
    }

    if (urlButtonShow.signup) {
      if (e?.banner_props?.signup) {
        resetLinkProps(e.banner_props.signup.link);
        Object.assign(e.banner_props.signup, {
          redirect_url: null,
        });
      }
    }

    let heading = {
      heading: e?.heading ?? null,
      heading_color: e?.heading_color ?? null,
    };

    let description = {
      description: e?.description ?? null,
      description_color: e?.description_color ?? null,
    };

    let button = {
      button: e?.button ?? null,
      button_color: e?.button_color ?? null,
      button_text_color: e?.button_text_color ?? null,
    };

    const MUTATIONAPI = `mutation MyMutation {
      insert_banners(objects: {  
        view_order: ${e?.view_order ?? 0}, 
        active: ${e?.active ?? false}, 
        ob_org_id: "${isAdmin ? e?.ob_org_id : user?.metadata?.org_id ?? ""}",
        image: ${e?.image ? `"${e.image}"` : null}, 
        mobile_image: ${e?.mobile_image ? `"${e.mobile_image}"` : null}, 
        banner_props: ${jsonConverter(e?.banner_props ?? {})},
      }) {
        affected_rows
      }
    }`;

    const imageSubmit = async () => {
      const { data } = await nhost.graphql.request(MUTATIONAPI);
      if (data) {
        setOpen(true);
        setTimeout(() => {
          setOpen(false);
        }, 4000);
        tableQueryResult?.refetch();
        closeCreateModal();
        formProps?.form?.resetFields();
        setUrlList([]);
        setMobileUrlList([]);
        return data;
      }
    };

    imageSubmit();
  };

  const handleOk = () => {
    setOpen(false);
  };

  const handleBannerPageChange = (value: any) => {
    setSelectedBannerPage(value);
  };

  const handleBannerTypeChange = (value: any) => {
    setSelectedBannerType(value);
  };
  const selectBannerPageOptions = [
    { label: "Home", value: "Home" },
    { label: "Contact", value: "Contact" },
    { label: "About", value: "About" },
    { label: "FAQ", value: "FAQ" },
  ];

  const selectHomeOptions = [
    { label: "Main", value: "Main" },
    { label: "Special Banner", value: "Special Banner" },
    { label: "Card", value: "Card" },
    { label: "Side bar", value: "Side bar" },
  ];

  const selectOtherPageOptions = [
    { label: "Main", value: "Main" },
    { label: "Side bar", value: "Side bar" },
  ];

  const selectOptions =
    selectedBannerPage == "Home" ? selectHomeOptions : selectOtherPageOptions;

  const handleLinkTypeChange = (value: any) => {
    setSelectedLinkType(value);
  };

  const linkSelectOptions = [
    { label: "Button", value: "Button" },
    { label: "Link", value: "Link" },
  ];

  const handleUrlChange = (value: any) => {
    setSelectedUrl(value);
  };

  const urlSelectOptions = [
    { label: "Category", value: "category" },
    { label: "Signin", value: "signin" },
    { label: "Register", value: "register" },
    { label: "Favourites", value: "my-account/favourites" },
    { label: "Orders", value: "my-account/orders" },
  ];

  const handleLocationChange = (value: any) => {
    setSelectedLocation(value);
  };
  
  const locationSelectOptions = [
    { label: "Below Main banner", value: "Below Main banner" },
    { label: "Below Cards", value: "Below Cards" },
    { label: "Below Feature Product", value: "Below Feature Product" },
    { label: "Below Category", value: "Below Category" },
    { label: "Above Footer", value: "Above Footer" },
  ];

  const sidebarLocationOptions = [
    { label: "Left", value: "Left" },
    { label: "Right", value: "Right" },
  ];

  const handelHead = () => {
    setHeadingShow({
      ...headingShow,
      signup: !headingShow.signup,
    });
  };

  const handelHeadLogin = () => {
    setHeadingShow({
      ...headingShow,
      login: !headingShow.login,
    });
  };

  const handelUrlButton = () => {
    setURLButtonShow({
      ...urlButtonShow,
      signup: !urlButtonShow.signup,
    });
  };

  const handelUrlButtonLogin = () => {
    setURLButtonShow({
      ...urlButtonShow,
      login: !urlButtonShow.login,
    });
  };

  const addLoginItem = () => {
    setLoginItems([...loginItems, { id: uuidv4() }]);
  };

  const removeLoginItem = (id: string) => {
    if (loginItems.length > 1) {
      setLoginItems(loginItems.filter((item) => item.id !== id));
    } else {
      message.error("At least one login item is required");
    }
  };

  const addSignupItem = () => {
    setSignupItems([...signupItems, { id: uuidv4() }]);
  };

  const removeSignupItem = (id: string) => {
    if (signupItems.length > 1) {
      setSignupItems(signupItems.filter((item) => item.id !== id));
    } else {
      message.error("At least one signup item is required");
    }
  };

  const onFinish = (values: any) => {
    const { banner_props, ...rest } = values;
    // Ensure bannerProps is an object
    let bannerProps: any = {};
    try {
      bannerProps = jsonConverter(banner_props) || {};
      if (typeof bannerProps !== "object") {
        bannerProps = {};
      }
    } catch (error) {
      console.error("Error converting banner_props:", error);
    }

    // Ensure login and signup items are properly formatted as arrays
    if (banner_props?.login) {
      // Filter out any empty items
      const loginData = Array.isArray(banner_props.login)
        ? banner_props.login.filter(
            (item: any) =>
              item &&
              (item.heading?.title ||
                item.description?.description ||
                item.link?.link_text)
          )
        : [];

      if (loginData.length === 0 && selectedBannerType === "Login") {
        message.error("Please add at least one login item with content");
        return;
      }

      bannerProps.login = loginData;
    }

    if (banner_props?.signup) {
      // Filter out any empty items
      const signupData = Array.isArray(banner_props.signup)
        ? banner_props.signup.filter(
            (item: any) =>
              item &&
              (item.heading?.title ||
                item.description?.description ||
                item.link?.link_text)
          )
        : [];

      if (signupData.length === 0 && selectedBannerType === "Signup") {
        message.error("Please add at least one signup item with content");
        return;
      }

      bannerProps.signup = signupData;
    }

    // Add image data to the submission
    const submitData = {
      ...rest,
      active: true,
      banner_props: {
        banner_page: banner_props?.banner_page,
        banner_type: banner_props?.banner_type,
        side_bar_location: banner_props?.side_bar_location,
        special_banner_location: banner_props?.special_banner_location,
        login: banner_props?.login,
        signup: banner_props?.signup,
      },
      image: urlList,
      mobile_image: mobileUrlList.join(","),
    };

    // console.log("submitData", submitData);
    apidata(submitData);
  };

  return (
    <>
      <Modal
        {...modalProps}
        title="Create Banner"
        onCancel={closeCreateModal}
        visible={createModalVisible}
        className="create_banner"
      >
        <Form {...formProps} onFinish={onFinish} size="large" layout="vertical">
          {isAdmin && (
            <Form.Item
              name="ob_org_id"
              label="Organization"
              rules={[
                {
                  required: true,
                  message: "Please Select Organization!",
                },
              ]}
              initialValue={user?.metadata?.org_id}
            >
              <Select {...OBOrgSelectProps} />
            </Form.Item>
          )}
          <Form.Item
            name={["banner_props", "banner_page"]}
            label="Banner Page"
            rules={[{ required: true, message: "Please Select Banner Page!" }]}
          >
            <Select
              onChange={handleBannerPageChange}
              value={selectedBannerPage}
            >
              {selectBannerPageOptions.map((option: any) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <div className="banner_props">
            <Form.Item
              name={["banner_props", "banner_type"]}
              label="Banner Type"
              rules={[
                { required: true, message: "Please Select Banner Type!" },
              ]}
            >
              <Select
                onChange={handleBannerTypeChange}
                value={selectedBannerType}
              >
                {selectOptions?.map((option: any) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {selectedBannerType != "Special Banner" ? (
              <>
                {selectedBannerType == "Side bar" ? (
                  <>
                    <Form.Item
                      name={["banner_props", "side_bar_location"]}
                      label="Location"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Side bar Location!",
                        },
                      ]}
                    >
                      <Select onChange={handleLocationChange}>
                        {sidebarLocationOptions.map((option: any) => (
                          <Select.Option
                            key={option.value}
                            value={option.value}
                          >
                            {option.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                <Form.Item
                  name={["banner_props", "special_banner_location"]}
                  label="Location"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Banner Location!",
                    },
                  ]}
                >
                  <Select
                    onChange={handleLocationChange}
                    value={selectedLocation}
                  >
                    {locationSelectOptions.map((option: any) => (
                      <Select.Option key={option.value} value={option.value}>
                        {option.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </>
            )}
          </div>
          <div className="upload_images">
            <Form.Item
              label={
                selectedBannerType == "Side bar" ? (
                  <div>
                    Upload Desktop Banner Image{" "}
                    <pre>
                      (When uploading an image, please ensure it has a{" "}
                      <strong> 2:3 </strong> aspect ratio for the best viewing
                      experience)
                    </pre>
                  </div>
                ) : (
                  "Upload Dashbord Banner Image"
                )
              }
              name="image"
              rules={[{ required: true, message: "Please Select Image!" }]}
            >
              <div className="Image_div">
                <div>
                  <Dragger
                    showUploadList={false}
                    {...props}
                    name="image"
                    customRequest={uploadImages}
                    accept="image/*"
                  >
                    <p className="ant-upload-drag-icon">
                      <PictureOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drag image file here to upload(or Click)
                    </p>
                  </Dragger>
                </div>
                <div className="category_img_div">
                  <h1 style={{ fontSize: "14px" }}>
                    Uploaded image will be displayed here
                  </h1>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {urlList?.map((url: any, index: any) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img className="category_img" src={url} />
                          {url ? (
                            <span>
                              <DeleteOutlined
                                onClick={() => removeImage(index)}
                              />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Form.Item>
            {selectedBannerType == "Main" && (
              <Form.Item
                label={
                  <div>
                    Upload Mobile Banner Image{" "}
                    <pre>
                      (When uploading an image, please ensure it has a{" "}
                      <strong> 450 x 222 </strong> pixel for the best viewing
                      experience)
                    </pre>
                  </div>
                }
                name="mobile_image"
                // rules={[{ required: true, message: 'Please Select Image!' }]}
              >
                <div className="Image_div">
                  <div>
                    <Dragger
                      showUploadList={false}
                      {...props}
                      name="image"
                      customRequest={uploadMobileImages}
                      accept="image/*"
                    >
                      <p className="ant-upload-drag-icon">
                        <PictureOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drag image file here to upload(or Click)
                      </p>
                    </Dragger>
                  </div>
                  <div className="category_img_div">
                    <h1 style={{ fontSize: "14px" }}>
                      Uploaded image will be displayed here
                    </h1>
                    {mobileUrlList?.map((url: any) => {
                      return (
                        <div>
                          <img className="category_img" src={url} />
                          {url ? (
                            <span>
                              <DeleteOutlined onClick={removeMobileImage} />
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Form.Item>
            )}
          </div>
          <Tabs>
            <TabPane tab="Signup" key={1}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "16px",
                }}
              >
                <button
                  type="button"
                  onClick={addSignupItem}
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "#1890ff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Add Signup Item
                </button>
              </div>

              {signupItems.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    border: "1px solid #f0f0f0",
                    padding: "16px",
                    marginBottom: "16px",
                    borderRadius: "4px",
                    position: "relative",
                  }}
                >
                  {signupItems.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeSignupItem(item.id)}
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        background: "#ff4d4f",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        cursor: "pointer",
                      }}
                    >
                      Remove
                    </button>
                  )}

                  <div className="heading">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                      aria-disabled={headingShow.signup}
                    >
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "heading",
                          "title",
                        ]}
                        label={
                          <span
                            style={{ color: headingShow.signup ? "gray" : "" }}
                          >
                            Heading
                          </span>
                        }
                        rules={[
                          {
                            required: headingShow.signup ? false : true,
                            message: "Please Enter Heading!",
                          },
                        ]}
                        style={{ width: "85%" }}
                      >
                        <TextArea rows={1} disabled={headingShow.signup} />
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignSelf: "flex-end",
                        }}
                      >
                        <Checkbox onClick={() => handelHead()}>Show</Checkbox>
                      </Form.Item>
                    </div>

                    <Form.Item
                      name={[
                        "banner_props",
                        "signup",
                        index,
                        "heading",
                        "heading_color",
                      ]}
                      label={
                        <span
                          style={{ color: headingShow.signup ? "gray" : "" }}
                        >
                          Color
                        </span>
                      }
                      initialValue={headingShow.signup ? "#080808" : "#808080"}
                    >
                      <Input type="color" disabled={headingShow.signup} />
                    </Form.Item>
                  </div>

                  {selectedBannerType != "Card" ? (
                    <div className="description">
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "description",
                          "description",
                        ]}
                        label="Description"
                        rules={[
                          {
                            max: 750,
                            message: "Description cannot exceed 750 characters",
                          },
                        ]}
                      >
                        <TextArea rows={2} maxLength={750} />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "description",
                          "description_color",
                        ]}
                        label="Color"
                        initialValue={"#080808"}
                      >
                        <Input type="color" />
                      </Form.Item>
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedBannerPage == "Home" ? (
                    <div className="button">
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "link",
                          "link_type",
                        ]}
                        label={
                          <span
                            style={{
                              color: urlButtonShow.signup ? "gray" : "",
                            }}
                          >
                            Link Type
                          </span>
                        }
                        rules={[
                          {
                            required: urlButtonShow.signup ? false : true,
                            message: "Please Select Link Type!",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleLinkTypeChange}
                          value={selectedLinkType}
                          disabled={urlButtonShow.signup}
                        >
                          {linkSelectOptions.map((option: any) => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "link",
                          "link_text",
                        ]}
                        label={
                          <span
                            style={{
                              color: urlButtonShow.signup ? "gray" : "",
                            }}
                          >
                            Link Name
                          </span>
                        }
                        rules={[
                          {
                            required: urlButtonShow.signup ? false : true,
                            message: "Please Enter Link Name!",
                          },
                        ]}
                      >
                        <TextArea rows={1} disabled={urlButtonShow.signup} />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "link",
                          "link_color",
                        ]}
                        label={
                          <span
                            style={{
                              color: urlButtonShow.signup ? "gray" : "",
                            }}
                          >
                            Color
                          </span>
                        }
                        initialValue={"#080808"}
                      >
                        <Input type="color" disabled={urlButtonShow.signup} />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "signup",
                          index,
                          "link",
                          "link_text_color",
                        ]}
                        label={
                          <span
                            style={{
                              color: urlButtonShow.signup ? "gray" : "",
                            }}
                          >
                            Text Color
                          </span>
                        }
                        initialValue={"#f5f5f5"}
                      >
                        <Input type="color" disabled={urlButtonShow.signup} />
                      </Form.Item>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="others">
                    <div>
                      {selectedBannerPage == "Home" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Form.Item
                            name={[
                              "banner_props",
                              "signup",
                              index,
                              "redirect_url",
                            ]}
                            label={
                              <span
                                style={{
                                  color: urlButtonShow.signup ? "gray" : "",
                                }}
                              >
                                Url
                              </span>
                            }
                            style={{ color: "gray", width: "100%" }}
                            rules={[
                              {
                                required: urlButtonShow.signup ? false : true,
                                message: "Please Select Link Url!",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleUrlChange}
                              value={selectedUrl}
                              disabled={urlButtonShow.signup}
                            >
                              {urlSelectOptions.map((option: any) => (
                                <Select.Option
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "flex",
                              alignItems: "center",
                              alignSelf: "flex-end",
                            }}
                          >
                            <Checkbox onClick={() => handelUrlButton()}>
                              Show
                            </Checkbox>
                          </Form.Item>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </TabPane>
            <TabPane tab="Login" key={2}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "16px",
                }}
              >
                <button
                  type="button"
                  onClick={addLoginItem}
                  style={{
                    padding: "8px 16px",
                    backgroundColor: "#1890ff",
                    color: "white",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  Add Login Item
                </button>
              </div>

              {loginItems.map((item, index) => (
                <div
                  key={item.id}
                  style={{
                    border: "1px solid #f0f0f0",
                    padding: "16px",
                    marginBottom: "16px",
                    borderRadius: "4px",
                    position: "relative",
                  }}
                >
                  {loginItems.length > 1 && (
                    <button
                      type="button"
                      onClick={() => removeLoginItem(item.id)}
                      style={{
                        position: "absolute",
                        top: "8px",
                        right: "8px",
                        background: "#ff4d4f",
                        color: "white",
                        border: "none",
                        borderRadius: "4px",
                        padding: "4px 8px",
                        cursor: "pointer",
                      }}
                    >
                      Remove
                    </button>
                  )}

                  <div className="heading">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "20px",
                      }}
                      aria-disabled={headingShow.login}
                    >
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "heading",
                          "title",
                        ]}
                        label={
                          <span
                            style={{ color: headingShow.login ? "gray" : "" }}
                          >
                            Heading
                          </span>
                        }
                        rules={[
                          {
                            required: headingShow.login ? false : true,
                            message: "Please Enter Heading!",
                          },
                        ]}
                        style={{ width: "85%" }}
                      >
                        <TextArea rows={1} disabled={headingShow.login} />
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignSelf: "flex-end",
                        }}
                      >
                        <Checkbox onClick={() => handelHeadLogin()}>
                          Show
                        </Checkbox>
                      </Form.Item>
                    </div>

                    <Form.Item
                      name={[
                        "banner_props",
                        "login",
                        index,
                        "heading",
                        "heading_color",
                      ]}
                      label={
                        <span
                          style={{ color: headingShow.login ? "gray" : "" }}
                        >
                          Color
                        </span>
                      }
                      initialValue={headingShow.login ? "#080808" : "#808080"}
                    >
                      <Input type="color" disabled={headingShow.login} />
                    </Form.Item>
                  </div>

                  {selectedBannerType != "Card" ? (
                    <div className="description">
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "description",
                          "description",
                        ]}
                        label="Description"
                      >
                        <TextArea rows={2} />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "description",
                          "description_color",
                        ]}
                        label="Color"
                        initialValue={"#080808"}
                      >
                        <Input type="color" />
                      </Form.Item>
                    </div>
                  ) : (
                    <></>
                  )}

                  {selectedBannerPage == "Home" ? (
                    <div className="button">
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "link",
                          "link_type",
                        ]}
                        label={
                          <span
                            style={{ color: urlButtonShow.login ? "gray" : "" }}
                          >
                            Link Type
                          </span>
                        }
                        rules={[
                          {
                            required: urlButtonShow.login ? false : true,
                            message: "Please Select Link Type!",
                          },
                        ]}
                      >
                        <Select
                          onChange={handleLinkTypeChange}
                          value={selectedLinkType}
                          disabled={urlButtonShow.login}
                        >
                          {linkSelectOptions.map((option: any) => (
                            <Select.Option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "link",
                          "link_text",
                        ]}
                        label={
                          <span
                            style={{ color: urlButtonShow.login ? "gray" : "" }}
                          >
                            Link Name
                          </span>
                        }
                        rules={[
                          {
                            required: urlButtonShow.login ? false : true,
                            message: "Please Enter Link Name!",
                          },
                        ]}
                      >
                        <TextArea rows={1} disabled={urlButtonShow.login} />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "link",
                          "link_color",
                        ]}
                        label={
                          <span
                            style={{ color: urlButtonShow.login ? "gray" : "" }}
                          >
                            Color
                          </span>
                        }
                        initialValue={"#080808"}
                      >
                        <Input type="color" disabled={urlButtonShow.login} />
                      </Form.Item>
                      <Form.Item
                        name={[
                          "banner_props",
                          "login",
                          index,
                          "link",
                          "link_text_color",
                        ]}
                        label={
                          <span
                            style={{ color: urlButtonShow.login ? "gray" : "" }}
                          >
                            Text Color
                          </span>
                        }
                        initialValue={"#f5f5f5"}
                      >
                        <Input type="color" disabled={urlButtonShow.login} />
                      </Form.Item>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="others">
                    <div>
                      {selectedBannerPage == "Home" ? (
                        <div
                          style={{
                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center",
                            gap: "20px",
                          }}
                        >
                          <Form.Item
                            name={[
                              "banner_props",
                              "login",
                              index,
                              "redirect_url",
                            ]}
                            label={
                              <span
                                style={{
                                  color: urlButtonShow.login ? "gray" : "",
                                }}
                              >
                                Url
                              </span>
                            }
                            style={{ color: "gray", width: "100%" }}
                            rules={[
                              {
                                required: urlButtonShow.login ? false : true,
                                message: "Please Select Link Url!",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleUrlChange}
                              value={selectedUrl}
                              disabled={urlButtonShow.login}
                            >
                              {urlSelectOptions.map((option: any) => (
                                <Select.Option
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            style={{
                              display: "flex",
                              alignItems: "center",
                              alignSelf: "flex-end",
                            }}
                          >
                            <Checkbox onClick={() => handelUrlButtonLogin()}>
                              Show
                            </Checkbox>
                          </Form.Item>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </TabPane>
          </Tabs>
        </Form>
      </Modal>
    </>
  );
};
